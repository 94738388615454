import React from "react";
import toast from "react-hot-toast";
import axios, {AxiosError, AxiosResponse} from "axios";
import {Link, useSearchParams} from "react-router-dom";
import PageProperties from "../../core/PageProperties";
import CourseModuleContent from "../../types/CourseModuleContent";
import {PageHelperInstance} from "../../core/PageHelper";
import RestResponseWrapper from "../../core/RestResponseWrapper";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

const ENTITY_NAME_SINGULAR: string = "CourseModuleContent";
const ENTITY_NAME_PLURAL: string = "CourseModuleContents";
const RESOURCE_URL: string = ServiceBaseURL + "course_module_contents/";

export default function CourseModuleContentsListPageInner(properties: PageProperties | undefined = undefined) {

    console.log("CourseModuleContentsListPageInner (start)");

    const [error, setError] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(true);
    const [result, setResult] = React.useState<CourseModuleContent[] | undefined>(undefined);

    const [trigger, setTrigger] = React.useState<number>(0);

    const [searchParams] = useSearchParams();
    const query = PageHelperInstance.createQuery(properties, searchParams);

    React.useEffect(() => {

        console.log("useEffect()");

        axios.get(RESOURCE_URL + query)
            .then((responseObject: AxiosResponse) => {
                console.log(responseObject);
                const restResult: RestResponseWrapper<CourseModuleContent[]> = responseObject.data;
                if (restResult.errorMessage !== null) {
                    setLoading(false);
                    toast.error("login failed: " + restResult.errorMessage, {
                        id: 'error1',
                    });
                    console.log("load failed: " + restResult.errorMessage);
                } else {
                    setResult(restResult.response as CourseModuleContent[]);
                    setLoading(false);
                    console.log("load successful");
                }
            })
            .catch((errorObject: AxiosError | Error) => {
                if (axios.isAxiosError(errorObject)) {
                    let axiosError = errorObject as AxiosError;
                    if (axiosError.code === 'ERR_NETWORK') {
                        toast.error("network error: is the service running?", {
                            id: 'error2',
                        });
                        console.log("network error: is the service running?")
                    } else {
                        toast.error(axiosError.message, {
                            id: 'error3',
                        });
                        console.log(axiosError.message);
                    }
                } else {
                    toast.error(errorObject.message, {
                        id: 'error4',
                    });
                    console.log(errorObject.message);
                }
                setLoading(false);
            });

    }, [trigger]);

    if (loading) {
        return <b>LOADING</b>;
    }

    if (error) {
        toast.error(error, {
            id: 'error1',
        });
        return <div className='error-box'>{error}</div>;
    }

    function showContent() {

        if ((result === undefined) || (result.length === 0)) {
            return (
                <b>No {ENTITY_NAME_PLURAL}</b>
            )
        }

        return (
            <>
                <table border={1}>
                    <thead>
                    <tr>
                        <th align={"left"}>sequence</th>
                        <th align={"left"}>contentType</th>
                    </tr>
                    </thead>
                    <tbody>
                    {result.map((courseModuleContent, index) =>
                        <>
                            <tr>
                                <td align={"left"}><Link
                                    to={"/course_module_contents/view/" + courseModuleContent.id}>{courseModuleContent.sequence}</Link>
                                </td>
                                <td align={"left"}><Link
                                    to={"/course_module_contents/view/" + courseModuleContent.id}>{courseModuleContent.contentType}</Link>
                                </td>
                            </tr>
                        </>
                    )}
                    </tbody>
                </table>
            </>)
    }

    return (
        <>
            {showContent()}
        </>
    )

}
