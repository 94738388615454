
export default interface CourseModuleContent {
    id: number | undefined;
    courseModuleId: number | undefined;
    contentType: string | undefined;
    sequence: number | undefined;
    content: string | undefined;
}

export function createCourseModuleContent(): CourseModuleContent {
    return {
        id: undefined,
        courseModuleId: undefined,
        contentType: undefined,
        sequence: undefined,
        content: undefined
    };
}
