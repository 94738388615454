import React from "react";
import CourseModulesViewPageInner from "./CourseModulesViewPageInner";
import Menu from "../../../components/Menu";

export default function CourseModulesViewPage() {

    return (
        <>
            <Menu/>
            <h1>Course Module</h1>
            <CourseModulesViewPageInner/>
        </>
    )

}
