import React from "react";
import MyCoursesListPageInner from "./MyCoursesListPageInner";
import Menu from "../../../components/Menu";

export default function MyCoursesListPage() {

    return (
        <>
            <Menu/>
            <h1>My Courses</h1>
            {<MyCoursesListPageInner/>}
        </>
    )

}
