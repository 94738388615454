import React from "react";
import SkillGroupsListPageInner from "./SkillGroupsListPageInner";
import Menu from "../../../components/Menu";

export default function SkillGroupsListPage() {

    return (
        <>
            <Menu/>
            <h1>Skill Groups</h1>
            <SkillGroupsListPageInner />
        </>
    )

}
