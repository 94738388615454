
export default interface SkillGroup {
    id: number | undefined;
    name: string | undefined;
}

export function createSkillGroup(): SkillGroup {
    return {
        id: undefined,
        name: undefined
    };
}
