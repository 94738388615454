
export default interface CourseModule {
    id: number | undefined;
    courseId: number | undefined;
    name: string | undefined;
    sequence: number | undefined;
    descriptionShort: string | undefined;
    descriptionLong: string | undefined;
}

export function createCourseModule(): CourseModule {
    return {
        id: undefined,
        courseId: undefined,
        name: undefined,
        sequence: undefined,
        descriptionShort: undefined,
        descriptionLong: undefined
    };
}
