import React from "react";
import {useParams} from "react-router-dom";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import Course, {createCourse} from "../../types/Course";
import CourseTag from "../../types/CourseTag";
import {RestClient} from "../../core/RestClient";
import CourseCategory from "../../types/CourseCategory";
import CourseModule, {createCourseModule} from "../../types/CourseModule";
import simpleToast from "../../core/SimpleToast";
import CourseCourseTag, {createCourseCourseTag} from "../../types/CourseCourseTag";
import CourseCourseCategory, {createCourseCourseCategory} from "../../types/CourseCourseCategory";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

export default function CoursesEditPageInner(): JSX.Element {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    const [course, setCourse] = React.useState<Course | undefined>(undefined);
    const [courseTags, setCourseTags] = React.useState<CourseTag[] | undefined>(undefined);
    const [allCourseTags, setAllCourseTags] = React.useState<CourseTag[] | undefined>(undefined);
    const [courseCategories, setCourseCategories] = React.useState<CourseCategory[] | undefined>(undefined);
    const [allCourseCategories, setAllCourseCategories] = React.useState<CourseCategory[] | undefined>(undefined);
    const [courseModules, setCourseModules] = React.useState<CourseModule[] | undefined>(undefined);

    //

    React.useEffect(() => {

        RestClient.get<Course>(
            ServiceBaseURL + "courses/" + id,
            setErrorMessage,
            undefined,
            setCourse,
            "loading course: "
        );

        RestClient.get<CourseTag[]>(
            ServiceBaseURL + "course_tags/?courseId=" + id,
            setErrorMessage,
            undefined,
            setCourseTags,
            "loading course tags: "
        );

        RestClient.get<CourseTag[]>(
            ServiceBaseURL + "course_tags",
            setErrorMessage,
            undefined,
            setAllCourseTags,
            "loading all course tags: "
        );

        RestClient.get<CourseCategory[]>(
            ServiceBaseURL + "course_categories/?courseId=" + id,
            setErrorMessage,
            undefined,
            setCourseCategories,
            "loading course categories: "
        );

        RestClient.get<CourseCategory[]>(
            ServiceBaseURL + "course_categories",
            setErrorMessage,
            undefined,
            setAllCourseCategories,
            "loading all course categories: "
        );

        RestClient.get<CourseModule[]>(
            ServiceBaseURL + "course_modules/?courseId=" + id,
            setErrorMessage,
            undefined,
            setCourseModules,
            "loading course modules: "
        );
    }, [successMessage]);


    /*
    ****************************************************************************************************************************************************
     */

    function handleSubmitEditCourseDetailsForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#editCourseDetailsForm") as HTMLFormElement;
        if (form !== null) {
            let courseF: Course = createCourse();

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            courseF.id = parseInt(id as string);
            console.log(formData.get("name")?.toString().length);
            if (formData.get("name")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'name'");
                return;
            }
            courseF.name = formData.get("name") as string;

            RestClient.put<Course>(
                ServiceBaseURL + "courses/" + id,
                courseF,
                setErrorMessage,
                setSuccessMessage,
                "updating course :",
                "updated course details"
            );

        }
    }

    function handleSubmitAddCourseTagForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#addCourseTagForm") as HTMLFormElement;
        if (form !== null) {
            let courseCourseF: CourseCourseTag = createCourseCourseTag();

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            courseCourseF.courseId = parseInt(id as string);
            let linkedId: number = parseInt(formData.get("linked_id")?.toString() as string);
            if (linkedId < 0) {
                simpleToast.error("no entry selected");
                return;
            }
            courseCourseF.courseTagId = linkedId;

            RestClient.post<CourseCourseTag>(
                ServiceBaseURL + "course_course_tags/",
                courseCourseF,
                setErrorMessage,
                setSuccessMessage,
                "adding course tag :",
                "course tag added"
            );

        }

    }

    function handleSubmitRemoveCourseTagForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#removeCourseTagForm") as HTMLFormElement;
        if (form !== null) {

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            let linkedId: number = parseInt(formData.get("linked_id")?.toString() as string);
            if (linkedId < 0) {
                simpleToast.error("no entry selected");
                return;
            }

            RestClient.delete(
                ServiceBaseURL + "course_course_tags/" + id + "/" + linkedId,
                setErrorMessage,
                setSuccessMessage,
                "removing course tag :",
                "course tag removed"
            );

        }

    }

    function handleSubmitAddCourseCategoryForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#addCourseCategoryForm") as HTMLFormElement;
        if (form !== null) {
            let courseCourseF: CourseCourseCategory = createCourseCourseCategory();

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            courseCourseF.courseId = parseInt(id as string);
            let linkedId: number = parseInt(formData.get("linked_id")?.toString() as string);
            if (linkedId < 0) {
                simpleToast.error("no entry selected");
                return;
            }
            courseCourseF.courseCategoryId = linkedId;

            RestClient.post<CourseCourseCategory>(
                ServiceBaseURL + "course_course_categories/",
                courseCourseF,
                setErrorMessage,
                setSuccessMessage,
                "adding course category :",
                "course category added"
            );

        }

    }

    function handleSubmitRemoveCourseCategoryForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#removeCourseCategoryForm") as HTMLFormElement;
        if (form !== null) {

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            let linkedId: number = parseInt(formData.get("linked_id")?.toString() as string);
            if (linkedId < 0) {
                simpleToast.error("no entry selected");
                return;
            }

            RestClient.delete(
                ServiceBaseURL + "course_course_categories/" + id + "/" + linkedId,
                setErrorMessage,
                setSuccessMessage,
                "removing course tag :",
                "course tag removed"
            );

        }

    }

    function handleSubmitCreateCourseModuleForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#createCourseModuleForm") as HTMLFormElement;
        if (form !== null) {
            let courseF: CourseModule = createCourseModule();

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            courseF.courseId = parseInt(id as string);

            // name
            if (formData.get("name")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'name'");
                return;
            }
            courseF.name = formData.get("name") as string;

            // sequence
            if (formData.get("sequence")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'sequence'");
                return;
            }
            courseF.sequence = parseInt(formData.get("sequence") as string);
            if (isNaN(courseF.sequence)) {
                simpleToast.error("'sequence' is not a valid number");
                return;
            }

            // descriptions
            courseF.descriptionShort = formData.get("descriptionShort") as string;
            courseF.descriptionLong = formData.get("descriptionLong") as string;

            console.log(courseF);

            RestClient.post<CourseModule>(
                ServiceBaseURL + "course_modules",
                courseF,
                setErrorMessage,
                setSuccessMessage,
                "creating course module: ",
                "created course module"
            );


        }
    }

    function handleSubmitRemoveCourseModuleForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        simpleToast.error("remove course module disabled by default");
    }

    /*
    ****************************************************************************************************************************************************
     */


    function showEditCourseDetailsForm(courseC: Course): JSX.Element {

        let value_name = "";
        if ((courseC.name !== undefined)) {
            value_name = courseC.name as string;
        }

        return (
            <>
                <div className="big-form">
                    <form className="big-form" id="editCourseDetailsForm" onSubmit={handleSubmitEditCourseDetailsForm}>
                        <table className="full-width big-form">
                            <tbody className="big-form">

                            <tr>
                                <td valign="top"><label htmlFor='name'>Name</label></td>
                                <td><input id='name' name='name' size={120} defaultValue={value_name}/></td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Update Course Details</button>
                                    <input type="hidden" id="id" name="id" value="{id}"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </>
        )
    }

    function showAddCourseTagForm(allCourseTagsC: CourseTag[]): JSX.Element {
        if (allCourseTagsC.length === 0) {
            return (
                <b>No tags to add</b>
            )
        }

        return (
            <>
                <div className="big-form">
                    <form className="big-form" id="addCourseTagForm" onSubmit={handleSubmitAddCourseTagForm}>
                        <table className="full-width big-form">
                            <tbody className="big-form">
                            <tr>
                                <td valign="top"><label htmlFor='linked_id'>Tag</label></td>
                                <td>
                                    <select name="linked_id" id="linked_id">
                                        <option value={-1}>-- Please choose --</option>
                                        {allCourseTagsC.map((tag: CourseTag) =>
                                            <option value={tag.id}>{tag.name}</option>
                                        )}
                                    </select>

                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Add Course Tag</button>
                                    <input type="hidden" id="id" name="id" value="{id}"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </>
        )
    }

    function showRemoveCourseTagForm(courseTagsC: CourseTag[]): JSX.Element {

        if (courseTagsC.length === 0) {
            return (
                <b>No tags to remove</b>
            )
        }

        return (
            <>
                <div className="big-form">
                    <form className="big-form" id="removeCourseTagForm" onSubmit={handleSubmitRemoveCourseTagForm}>
                        <table className="full-width big-form">
                            <tbody className="big-form">
                            <tr>
                                <td valign="top"><label htmlFor='linked_id'>Tag</label></td>
                                <td>
                                    <select name="linked_id" id="linked_id">
                                        <option value={-1}>-- Please choose --</option>
                                        {courseTagsC.map((tag: CourseTag) =>
                                            <option value={tag.id}>{tag.name}</option>
                                        )}
                                    </select>

                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Remove Course Tag</button>
                                    <input type="hidden" id="id" name="id" value="{id}"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </>
        )
    }

    function showAddCourseCategoryForm(allCourseCategoriesC: CourseCategory[]): JSX.Element {
        if (allCourseCategoriesC.length === 0) {
            return (
                <b>No category to add</b>
            )
        }

        return (
            <>
                <div className="big-form">
                    <form className="big-form" id="addCourseCategoryForm" onSubmit={handleSubmitAddCourseCategoryForm}>
                        <table className="full-width big-form">
                            <tbody className="big-form">
                            <tr>
                                <td valign="top"><label htmlFor='linked_id'>Category</label></td>
                                <td>
                                    <select name="linked_id" id="linked_id">
                                        <option value={-1}>-- Please choose --</option>
                                        {allCourseCategoriesC.map((tag: CourseCategory) =>
                                            <option value={tag.id}>{tag.name}</option>
                                        )}
                                    </select>

                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Add Course Category</button>
                                    <input type="hidden" id="id" name="id" value="{id}"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </>
        )
    }

    function showRemoveCourseCategoryForm(courseCategoriesC: CourseCategory[]): JSX.Element {

        if (courseCategoriesC.length === 0) {
            return (
                <b>No category to remove</b>
            )
        }

        return (
            <>
                <div className="big-form">
                    <form className="big-form" id="removeCourseCategoryForm"
                          onSubmit={handleSubmitRemoveCourseCategoryForm}>
                        <table className="full-width big-form">
                            <tbody className="big-form">
                            <tr>
                                <td valign="top"><label htmlFor='linked_id'>Category</label></td>
                                <td>
                                    <select name="linked_id" id="linked_id">
                                        <option value={-1}>-- Please choose --</option>
                                        {courseCategoriesC.map((category: CourseCategory) =>
                                            <option value={category.id}>{category.name}</option>
                                        )}
                                    </select>

                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Remove Course Category</button>
                                    <input type="hidden" id="id" name="id" value="{id}"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </>
        )
    }

    function showCreateCourseModuleForm(): JSX.Element {

        return (
            <>
                <div className="big-form">
                    <form className="big-form" id="createCourseModuleForm" onSubmit={handleSubmitCreateCourseModuleForm}>
                        <table className="full-width big-form">
                            <tbody className="big-form">
                            <tr>
                                <td valign="top"><label htmlFor='sequence'>Sequence</label></td>
                                <td><input id='sequence' name='sequence' size={120}/></td>
                            </tr>
                            <tr>
                                <td valign="top"><label htmlFor='name'>Name</label></td>
                                <td><input id='name' name='name' size={120}/></td>
                            </tr>
                            <tr>
                                <td valign="top"><label htmlFor='descriptionShort'>Short Description</label></td>
                                <td><input id='descriptionShort' name='descriptionShort' size={120}/></td>
                            </tr>
                            <tr>
                                <td valign="top"><label htmlFor='descriptionLong'>Long Description</label></td>
                                <td><textarea id='descriptionLong' name='descriptionLong'/></td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Create Course Module</button>
                                    <input type="hidden" id="id" name="id" value="{id}"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </>
        )
    }

    function showRemoveCourseModuleForm(courseModulesC: CourseModule[]): JSX.Element {

        if (courseModulesC.length === 0) {
            return (
                <b>No modules to remove</b>
            )
        }

        return (
            <>
                <div className="big-form">
                    <form className="big-form" id="removeCourseModuleForm"
                          onSubmit={handleSubmitRemoveCourseModuleForm}>
                        <table className="full-width big-form">
                            <tbody className="big-form">
                            <tr>
                                <td valign="top"><label htmlFor='linked_id'>Module</label></td>
                                <td>
                                    <select name="linked_id" id="linked_id">
                                        <option value={-1}>-- Please choose --</option>
                                        {courseModulesC.map((module: CourseModule) =>
                                            <option value={module.id}>{module.sequence}.: {module.name}</option>
                                        )}
                                    </select>

                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Remove Course Category</button>
                                    <input type="hidden" id="id" name="id" value="{id}"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </>
        )
    }

    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    if (
        (course === undefined) ||
        (courseTags === undefined) ||
        (allCourseTags === undefined) ||
        (courseCategories === undefined) ||
        (allCourseCategories === undefined) ||
        (courseModules === undefined)
    ) {
        return (
            <>
                <i>loading</i>
            </>
        )
    }

    return (
        <>
            <h2>Edit Course Details</h2>
            {showEditCourseDetailsForm(course as Course)}
            <hr/>
            <h2>Add Course Tag</h2>
            {showAddCourseTagForm(allCourseTags as CourseTag[])}
            <hr/>
            <h2>Delete Course Tag</h2>
            {showRemoveCourseTagForm(courseTags as CourseTag[])}
            <hr/>
            <h2>Add Course Category</h2>
            {showAddCourseCategoryForm(allCourseCategories as CourseCategory[])}
            <hr/>
            <h2>Delete Course Category</h2>
            {showRemoveCourseCategoryForm(courseCategories as CourseCategory[])}
            <hr/>
            <h2>Create Course Module</h2>
            {showCreateCourseModuleForm()}
            <hr/>
            <h2>Delete Course Module</h2>
            {showRemoveCourseModuleForm(courseModules as CourseModule[])}
        </>
    )

}
