import React from "react";
import Menu from "../../../components/Menu";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import {RoleEnum} from "../../enums/RoleEnum";
import {MapHelperInstance} from "../../core/MapHelper";
import {Navigate} from "react-router-dom";
import CoursesListPageInner from "../courses/CoursesListPageInner";

export default function MyCoursesListPageInner() {

    let userSession = store.getState().session as UserSession | undefined;

    if (userSession === undefined) {
        return (
            <>
                <Navigate to="/"/>
            </>
        )
    }

    if (userSession.roleId !== RoleEnum.STUDENT) {
        return (
            <>
                <b>Your role does not allow course enrollment.</b>
            </>
        )
    }

    return (
        <>
            {<CoursesListPageInner propertyMap={MapHelperInstance.build1('enrolledUserId', '-1')}/>}
        </>
    )

}
