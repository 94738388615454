import React from "react";
import CourseTagsListPageInner from "./CourseTagsListPageInner";
import Menu from "../../../components/Menu";

export default function CourseTagsListPage() {

    return (
        <>
            <Menu/>
            <h1>Course Tags</h1>
            <CourseTagsListPageInner />
        </>
    )

}
