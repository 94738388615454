import React from "react";
import Menu from "../../../components/Menu";
import CoursesEditPageInner from "./CoursesEditPageInner";

export default function CoursesEditPage() {

    return (
        <>
            <Menu/>
            <h1>Edit Course</h1>
            <CoursesEditPageInner />
        </>
    )

}
