import React from "react";
import simpleToast from "../../core/SimpleToast";
import CourseModuleContent, {createCourseModuleContent} from "../../types/CourseModuleContent";
import Menu, {AUTH_TOKEN_PREFIX} from "../../../components/Menu";
import {useParams} from "react-router-dom";
import {RestClient} from "../../core/RestClient";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import axios from "axios";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

export default function CourseModuleContentsEditPage() {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;
    axios.defaults.headers.common['Authorization'] = AUTH_TOKEN_PREFIX + userSession?.token;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    const [courseModuleContent, setCourseModuleContent] = React.useState<CourseModuleContent | undefined>(undefined);

    React.useEffect(() => {
        console.log("React.useEffect()");

        RestClient.get<CourseModuleContent>(
            ServiceBaseURL + "course_module_contents/" + id,
            setErrorMessage,
            setSuccessMessage,
            setCourseModuleContent,
            "loading course module content: "
        );

    }, [successMessage]);

    function handleSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();

        let form = document.querySelector("#formId") as HTMLFormElement;
        if (form !== null) {
            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            let sendData: CourseModuleContent = createCourseModuleContent();

            // sequence
            sendData.sequence = parseInt(formData.get("sequence") as string);
            if (isNaN(sendData.sequence)) {
                simpleToast.error("'sequence' is not a valid number");
                return;
            }

            // contentType
            if (formData.get("contentType")?.toString().trim().length === 0) {
                simpleToast.error("missing 'contentType'");
                return;
            }
            sendData.contentType = formData.get("contentType")?.toString().trim();

            // content
            if (formData.get("content")?.toString().trim().length === 0) {
                simpleToast.error("missing 'content'");
                return;
            }
            sendData.content = formData.get("content")?.toString().trim();

            sendData.courseModuleId = courseModuleContent?.courseModuleId;
            sendData.id = parseInt(id as string);

            RestClient.put<CourseModuleContent>(
                ServiceBaseURL + "course_module_contents/" + id,
                sendData,
                setErrorMessage,
                setSuccessMessage,
                "updating course module content: ",
                "updated course module content"
            );
        }
    }


    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    if (
        (courseModuleContent === undefined)
    ) {
        return (
            <>
                <i>loading</i>
            </>
        )
    }


    return (
        <>
            <Menu/>
            <h1>Edit CourseModuleContent</h1>
            <div className="big-form">
                <form className="big-form" id="formId" onSubmit={handleSubmit}>
                    <table className="full-width big-form">
                        <tbody className="big-form">
                        <tr>
                            <td valign="top"><label htmlFor='sequence'>Sequence</label></td>
                            <td><input id='sequence' name='sequence' size={120}
                                       defaultValue={courseModuleContent.sequence}/></td>
                        </tr>
                        <tr>
                            <td valign="top"><label htmlFor='contentType'>Content Type</label></td>
                            <td><input id='contentType' name='contentType' size={120}
                                       defaultValue={courseModuleContent.contentType}/></td>
                        </tr>
                        <tr>
                            <td valign="top"><label htmlFor='content'>Content</label></td>
                            <td><textarea id='content'
                                          name='content'>{courseModuleContent.content}</textarea></td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <button type="submit">Update CourseModuleContent</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </>
    )

}
