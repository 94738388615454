
export default interface PasswordUpdateInfo {
    currentPassword: string | undefined;
    newPassword: string | undefined;
}

export function createPasswordUpdateInfo(): PasswordUpdateInfo {
    return {
        currentPassword: undefined,
        newPassword: undefined
    };
}
