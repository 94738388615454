
export default interface UserSkill {
    userId: number | undefined;
    skillId: number | undefined;
    rating: number | undefined;
}

export function createUserSkill(): UserSkill {
    return {
        userId: undefined,
        skillId: undefined,
        rating: undefined
    };
}
