import React from "react";
import {useParams} from "react-router-dom";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import {RestClient} from "../../core/RestClient";
import simpleToast from "../../core/SimpleToast";
import SkillGroup from "../../types/SkillGroup";
import Menu from "../../../components/Menu";
import Skill, {createSkill} from "../../types/Skill";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

export default function SkillsCreatePage(): JSX.Element {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    const [skillGroups, setSkillGroups] = React.useState<SkillGroup[] | undefined>(undefined);

    React.useEffect(() => {

        RestClient.get<SkillGroup[]>(
            ServiceBaseURL + "skill_groups/",
            setErrorMessage,
            undefined,
            setSkillGroups,
            "loading skill groups: "
        );

    }, [successMessage]);


    function handleSubmitCreateSkillForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#createSkillForm") as HTMLFormElement;
        if (form !== null) {
            let sendData: Skill = createSkill();

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            let linkedId: number = parseInt(formData.get("linked_id")?.toString() as string);
            if (linkedId < 0) {
                simpleToast.error("no entry selected");
                return;
            }
            sendData.skillGroupId = parseInt(formData.get("linked_id")?.toString() as string);

            // name
            if (formData.get("name")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'name'");
                return;
            }
            sendData.name = formData.get("name") as string;

            console.log(sendData);

            RestClient.post<Skill>(
                ServiceBaseURL + "skills",
                sendData,
                setErrorMessage,
                setSuccessMessage,
                "creating skill: ",
                "created skill"
            );
        }
    }

    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    if (
        (skillGroups === undefined)
    ) {
        return (
            <>
                <i>loading</i>
            </>
        )
    }

    if (
        (skillGroups.length === 0)
    ) {
        return (
            <>
                <b>No Skill Groups found !</b>
            </>
        )
    }

    return (
        <>
            <Menu/>
            <h1>Create Skill</h1>

            <div className="big-form">
                <form className="big-form" id="createSkillForm" onSubmit={handleSubmitCreateSkillForm}>
                    <table className="full-width big-form">
                        <tbody className="big-form">
                        <tr>
                            <td valign="top"><label htmlFor='linked_id'>Skill Group</label></td>
                            <td>
                                <select name="linked_id" id="linked_id">
                                    <option value={-1}>-- Please choose --</option>
                                    {skillGroups.map((skillGroup: SkillGroup) =>
                                        <option value={skillGroup.id}>{skillGroup.name}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top"><label htmlFor='name'>Name</label></td>
                            <td><input id='name' name='name' size={120}/></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <button type="submit">Create Skill</button>
                                <input type="hidden" id="id" name="id" value="{id}"/>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </>
    )

}
