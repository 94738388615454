import React from "react";
import toast from "react-hot-toast";
import axios, {AxiosError, AxiosResponse} from "axios";
import {Link} from "react-router-dom";
import CourseCategory from "../../types/CourseCategory";
import RestResponseWrapper from "../../core/RestResponseWrapper";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import {RoleEnum} from "../../enums/RoleEnum";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

const ENTITY_NAME_SINGULAR: string = "CourseCategory";
const ENTITY_NAME_PLURAL: string = "CourseCategories";
const RESOURCE_URL: string = ServiceBaseURL + "course_categories/";

export default function CourseCategoriesListPageInner() {

    let userSession = store.getState().session as UserSession | undefined;

    const [error, setError] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(true);
    const [result, setResult] = React.useState<CourseCategory[] | undefined>(undefined);

    const [trigger, setTrigger] = React.useState<number>(0);

    React.useEffect(() => {

        console.log("useEffect()");

        axios.get(RESOURCE_URL)
            .then((responseObject: AxiosResponse) => {
                console.log(responseObject);
                const restResult: RestResponseWrapper<CourseCategory[]> = responseObject.data;
                if (restResult.errorMessage !== null) {
                    setLoading(false);
                    toast.error("login failed: " + restResult.errorMessage, {
                        id: 'error1',
                    });
                    console.log("load failed: " + restResult.errorMessage);
                } else {
                    setResult(restResult.response as CourseCategory[]);
                    setLoading(false);
                    console.log("load successful");
                }
            })
            .catch((errorObject: AxiosError | Error) => {
                if (axios.isAxiosError(errorObject)) {
                    let axiosError = errorObject as AxiosError;
                    if (axiosError.code === 'ERR_NETWORK') {
                        toast.error("network error: is the service running?", {
                            id: 'error2',
                        });
                        console.log("network error: is the service running?")
                    } else {
                        toast.error(axiosError.message, {
                            id: 'error3',
                        });
                        console.log(axiosError.message);
                    }
                } else {
                    toast.error(errorObject.message, {
                        id: 'error4',
                    });
                    console.log(errorObject.message);
                }
                setLoading(false);
            });

    }, [trigger]);

    if (loading) {
        return <b>LOADING</b>;
    }

    if (error) {
        toast.error(error, {
            id: 'error1',
        });
        return <div className='error-box'>{error}</div>;
    }

    function showContent() {

        if ((result === undefined) || (result.length === 0)) {
            return (
                <b>No {ENTITY_NAME_PLURAL}</b>
            )
        }

        return (
            <>
                <table border={1}>
                    <thead>
                    <tr>
                        <th align={"left"}>Name</th>
                        {userSession?.roleId === RoleEnum.ADMIN ? <th align={"left"}>Edit</th> : ""}
                    </tr>
                    </thead>
                    <tbody>
                    {result.map((courseCategory, index) =>
                        <>
                            <tr>
                                <td align={"left"}>
                                    {courseCategory.name}
                                </td>
                                {userSession?.roleId === RoleEnum.ADMIN
                                    ? <td align={"left"}>
                                        <Link className={"menu-link"}
                                              to={"/course_categories/edit/" + courseCategory.id}>Edit</Link>
                                    </td>
                                    : ""}
                            </tr>
                        </>
                    )}
                    </tbody>
                </table>
            </>)
    }

    return (
        <>
            {showContent()}
        </>
    )

}
