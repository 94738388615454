import React from "react";
import Menu from "../../../components/Menu";
import CoursesSearchPageInner from "./CoursesSearchPageInner";

export default function CoursesSearchPage() {

    return (
        <>
            <Menu/>
            <h1>Search Courses</h1>
            <CoursesSearchPageInner />
        </>
    )

}
