import React from "react";
import {useParams} from "react-router-dom";
import Menu from "../../../components/Menu";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import simpleToast from "../../core/SimpleToast";
import {RestClient} from "../../core/RestClient";
import Organisation, {createOrganisation} from "../../types/Organisation";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

export default function OrganisationsCreatePage() {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    React.useEffect(() => {
        /* empty by intent */
    }, []);

    function handleSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#form") as HTMLFormElement;
        if (form !== null) {
            let sendData: Organisation = createOrganisation();

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            // name
            if (formData.get("name")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'name'");
                return;
            }
            sendData.name = formData.get("name") as string;

            RestClient.post<Organisation>(
                ServiceBaseURL + "organisations",
                sendData,
                setErrorMessage,
                setSuccessMessage,
                "creating organisation: ",
                "created organisation"
            );


        }
    }


    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    return (
        <>
            <Menu/>
            <h1>Create Organisation</h1>
            <div className="big-form">
                <form className="big-form" id="form" onSubmit={handleSubmit}>
                    <table className="full-width big-form">
                        <tbody className="big-form">
                        <tr>
                            <td valign="top"><label htmlFor='name'>Name</label></td>
                            <td><input id='name' name='name' size={120}/></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <button type="submit">Create Organisation</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </>
    )

}

