import React from "react";
import {Navigate} from "react-router-dom";
import {store} from "../../store";
import UserSession from "../../auth/UserSession";
import Menu from "../../components/Menu";
import simpleToast from "../core/SimpleToast";
import {RestClient} from "../core/RestClient";
import PasswordUpdateInfo, {createPasswordUpdateInfo} from "../types/PasswordUpdateInfo";
import {ServiceBaseURL} from "../../components/ServiceConfiguration";

export default function UpdatePasswordPage() {

    let userSession = store.getState().session as UserSession | undefined;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    function handleSubmitPassword(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#formId") as HTMLFormElement;
        if (form !== null) {
            let sendData: PasswordUpdateInfo = createPasswordUpdateInfo();

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            // current_password
            if (formData.get("current_password")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'current_password'");
                return;
            }
            sendData.currentPassword = formData.get("current_password") as string;

            // new_password
            if (formData.get("new_password")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'new_password'");
                return;
            }
            sendData.newPassword = formData.get("new_password") as string;

            RestClient.put<PasswordUpdateInfo>(
                ServiceBaseURL + "password_infos/-1",
                sendData,
                setErrorMessage,
                setSuccessMessage,
                "updating password: ",
                "updated password"
            );

        }

    }

    if (userSession === undefined) {
        return (
            <>
                <Navigate to="/"/>
            </>
        );
    }

    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    return (
        <>
            <Menu/>
            <div className="big-form">
                <form className="big-form" id="formId" onSubmit={handleSubmitPassword} >
                    <input type="hidden" id="timezone" name="timezone" value="Europe:Berlin"/>
                    <table className="full-width big-form">
                        <tbody className="big-form">
                        <tr>
                            <td valign="top"><label htmlFor='current_password'>Current Password</label></td>
                            <td><input /* type={"password"} */ id='current_password' name='current_password' size={120}/></td>
                        </tr>
                        <tr>
                            <td valign="top"><label htmlFor='new_password'>New Password</label></td>
                            <td><input /* type={"password"} */ id='new_password' name='new_password' size={120}/></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <button type="submit">Update Password</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>

        </>
    )

}