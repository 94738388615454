import PageProperties from "../../core/PageProperties";
import Menu from "../../../components/Menu";

export default function SkillsDeletePage(properties: PageProperties | undefined = undefined) {

    return (
        <>
            <Menu />
            <b>Skill deletion disabled</b>
        </>
    )

}
