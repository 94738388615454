import React from "react";
import OrganisationsListPageInner from "./OrganisationsListPageInner";
import Menu from "../../../components/Menu";

export default function OrganisationsListPage() {

    return (
        <>
            <Menu/>
            <h1>Organisations</h1>
            <OrganisationsListPageInner />
        </>
    )

}
