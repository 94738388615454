import PageProperties from "../../core/PageProperties";
import Menu from "../../../components/Menu";

export default function SkillGroupsDeletePage(properties: PageProperties | undefined = undefined) {

    return (
        <>
            <Menu />
            <b>Skill Group delete disabled</b>
        </>
    )

}
