// store.js
import { createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {sessionReducer} from "./auth/sessionReducer";

const persistConfig = {
    key: 'root',
    storage,
    // Specify the reducers you want to persist
    whitelist: ['session'], // In this example, we persist the 'user' reducer
};
const persistedReducer = persistReducer(persistConfig, sessionReducer);
export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
