import React from "react";
import axios, {AxiosError, AxiosResponse} from "axios";
import Course, {createCourse} from "../../types/Course";
import RestResponseWrapper from "../../core/RestResponseWrapper";
import simpleToast from "../../core/SimpleToast";
import Menu from "../../../components/Menu";
import {Navigate} from "react-router-dom";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

const ViewState_SHOW_FORM = 1;
const ViewState_SUBMIT_DATA = 2;
const ViewState_GOTO_VIEW = 4;
const ViewState_FAILED = 9;

const RESOURCE_URL: string = ServiceBaseURL + "courses/";

export default function CoursesCreatePage() {

    const [active, setActive] = React.useState<boolean>(true);
    const [result, setResult] = React.useState<Course | undefined>(undefined);
    const [payload, setPayload] = React.useState<Course | undefined>(undefined);
    const [error, setError] = React.useState<string>("");

    const [viewState, setViewState] = React.useState<number>(ViewState_SHOW_FORM);

    console.log("STATE: " + viewState);

    React.useEffect(() => {
        console.log("React.useEffect()");

        if (viewState === ViewState_SUBMIT_DATA) {
            axios.post(RESOURCE_URL, payload)
                .then((responseObject: AxiosResponse) => {
                    console.log(responseObject);
                    const restResult: RestResponseWrapper<Course> = responseObject.data;
                    if (restResult.errorMessage !== null) {
                        setActive(false);
                        simpleToast.error(restResult.errorMessage as string);
                        console.log("load failed: " + restResult.errorMessage);
                    } else {
                        setResult(restResult.response as Course);
                        setActive(false);
                        console.log("load successful");
                    }
                })
                .catch((errorObject: AxiosError | Error) => {
                    if (axios.isAxiosError(errorObject)) {
                        let axiosError = errorObject as AxiosError;
                        if (axiosError.code === 'ERR_NETWORK') {
                            simpleToast.error("network error: is the service running ?");
                            console.log("network error: is the service running?")
                        } else {
                            simpleToast.error(axiosError.message);
                            console.log(axiosError.message);
                        }
                    } else {
                        simpleToast.error(errorObject.message);
                        console.log(errorObject.message);
                    }
                    setActive(false);
                });

        }
    }, [payload]);

    if (viewState === ViewState_FAILED) {

        return (
            <>
                <b>{error}</b>
            </>
        );
    }

    function formToEntity(form: HTMLFormElement): Course | string {

        let formData = new FormData(form);
        let entity: Course = createCourse();

        console.log(formData);

        // name
        if (!formData.has("name")) {
            return "missing 'name'";
        }
        if ("" === (formData.get("name") as string)) {
            return "empty 'name'";
        }
        entity.name = formData.get("name") as string;

        return entity;
    }

    function handleSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();

        console.log("handleSubmit()")

        const form = document.querySelector("form");
        if (form !== null) {

            let entity = formToEntity(form);

            console.log(entity);
            if (typeof entity === "string") {
                console.error("ERROR(3):" + entity as string);
                simpleToast.error(entity as string);
            } else {
                console.log("send");
                // we can send this
                setPayload(entity as Course);
                setViewState(ViewState_SUBMIT_DATA);
            }

        }
    }


    if (error) {
        console.error("ERROR(1):" + error);
        simpleToast.error(error);
        setViewState(ViewState_FAILED);
        return <div className='error-box'>{error}</div>;
    }

    if (viewState === ViewState_SUBMIT_DATA) {
        if (active) {
            console.log("sending data")
            return <b>TRANSMITTING</b>;
        } else {
            console.log("data sent")
            if (error) {
                console.error("ERROR(2):" + error);
                simpleToast.error(error);
                setError("");
                setViewState(ViewState_SHOW_FORM);
            } else {
                simpleToast.success("Course created");
                // setViewState(ViewState_ENIITY_CREATED);
                setViewState(ViewState_GOTO_VIEW);
            }
        }
    }

    if (viewState === ViewState_SHOW_FORM) {

        return (
            <>
                <Menu/>
                <h1>Create Course</h1>
                <div className="big-form">
                    <form className="big-form" id="formId" onSubmit={handleSubmit}>
                        <table className="full-width big-form">
                            <tbody className="big-form">
                            <tr>
                                <td valign="top"><label htmlFor='name'>Name</label></td>
                                <td><input id='name' name='name' size={120}/></td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Create Course</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </>
        )
    }

    if (viewState === ViewState_GOTO_VIEW) {

        return (
            <>
                <Navigate to={"/"}/>
            </>
        );
    }

    return (
        <>
            <b>you shouldn't be here ...</b>
            <i>viewState: {viewState}</i>
        </>
    );

}

