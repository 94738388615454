import React from "react";
import {Navigate} from "react-router-dom";
import {store} from "../../store";
import {ACTION_CLEAR_SESSION} from "../../auth/sessionReducer";
import axios, {AxiosError, AxiosResponse} from "axios";
import toast from "react-hot-toast";
import Menu from "../../components/Menu";
import {ServiceBaseURL} from "../../components/ServiceConfiguration";

export default function LogoutPage() {

    const [loggedOut, setLoggedOut] = React.useState<boolean>(false);
    const [trigger, setTrigger] = React.useState<number>(0);

    React.useEffect(() => {
        console.log("useEffect()");
        if (loggedOut) {
            store.dispatch({type: ACTION_CLEAR_SESSION});
        } else {
            // wait our turn
            if (trigger > 0) {
                console.log("send delete");
                axios.delete(ServiceBaseURL + "user_session/0")
                    .then((responseObject: AxiosResponse) => {
                        console.log(responseObject);
                        toast.success("logged out", {
                            id: 'success1',
                        });
                        setLoggedOut(true);
                    })
                    .catch((errorObject: AxiosError | Error) => {
                        if (axios.isAxiosError(errorObject)) {
                            let axiosError = errorObject as AxiosError;
                            if (axiosError.code === 'ERR_NETWORK') {
                                console.log("network error: is the service running?")
                                toast.error("network error: is the service running?", {
                                    id: 'error1',
                                });
                            } else {
                                console.log(axiosError.message);
                                toast.error("error: " + axiosError.message, {
                                    id: 'error2',
                                });
                            }
                        } else {
                            console.log(errorObject.message);
                            toast.error("error: " + errorObject.message, {
                                id: 'error3',
                            });
                        }
                    });
            }
        }
    }, [trigger, loggedOut]);

    function doLogOut(): void {
        setTrigger(1);
    }

    if (loggedOut) {
        return (
            <>
                <Navigate to="/"/>
            </>
        );
    }

    return (
        <>
            <Menu/>
            <h1>Logout</h1>
            <div>
                <button onClick={() => doLogOut()} disabled={false}>Logout</button>
            </div>
        </>
    );

}
