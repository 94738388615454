import PageProperties from "../../core/PageProperties";
import Menu from "../../../components/Menu";

export default function CourseCategoriesDeletePage(properties: PageProperties | undefined = undefined) {

    return (
        <>
            <Menu/>
            <b>Course Category delete disabled</b>
        </>
    )

}
