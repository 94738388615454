
export default interface CourseCourseTag {
    courseId: number | undefined;
    courseTagId: number | undefined;
}

export function createCourseCourseTag(): CourseCourseTag {
    return {
        courseId: undefined,
        courseTagId: undefined
    };
}
