import React from "react";
import {useParams} from "react-router-dom";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import simpleToast from "../../core/SimpleToast";
import {RestClient} from "../../core/RestClient";
import {RoleEnum} from "../../enums/RoleEnum";
import {LanguageEnum} from "../../enums/LanguageEnum";
import Organisation from "../../types/Organisation";
import UserUpdateInfo from "../../types/UserUpdateInfo";
import UserCreateInfo, {createUserCreateInfo} from "../../types/UserCreateInfo";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

export default function UsersCreatePageInner(): JSX.Element {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    const [organisations, setOrganisations] = React.useState<Organisation[] | undefined>(undefined);

    //

    React.useEffect(() => {

        RestClient.get<Organisation[]>(
            ServiceBaseURL + "organisations/",
            setErrorMessage,
            undefined,
            setOrganisations,
            "loading organisations: "
        );

    }, [successMessage]);


    /*
    ****************************************************************************************************************************************************
     */

    function handleSubmitCreateUserForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#createUserForm") as HTMLFormElement;
        if (form !== null) {
            let sendData: UserCreateInfo = createUserCreateInfo();

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            // email
            if (formData.get("email")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'email'");
                return;
            }
            sendData.email = formData.get("email") as string;

            // lastName
            if (formData.get("lastName")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'lastName'");
                return;
            }
            sendData.lastName = formData.get("lastName") as string;

            // firstName
            if (formData.get("firstName")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'firstName'");
                return;
            }
            sendData.firstName = formData.get("firstName") as string;

            // enabled
            sendData.enabled = (formData.get("enabled") === "1");

            // organisationId
            sendData.organisationId = parseInt(formData.get("organisationId") as string);
            if (-1 === sendData.organisationId) {
                sendData.organisationId = undefined;
            }

            // phone
            if (formData.get("phone")?.toString().trim().length !== 0) {
                sendData.phone = formData.get("phone") as string;
            }

            // subscriptionStart
            if (formData.get("subscriptionStart")?.toString().trim().length !== 0) {
                sendData.subscriptionStart = parseInt(formData.get("subscriptionStart")?.toString().trim() as string);
                if (isNaN(sendData.subscriptionStart)) {
                    simpleToast.error("'subscriptionStart' is not a valid EPOCH");
                    return;
                }
            }

            // subscriptionEnd
            if (formData.get("subscriptionEnd")?.toString().trim().length !== 0) {
                sendData.subscriptionEnd = parseInt(formData.get("subscriptionEnd")?.toString().trim() as string);
                if (isNaN(sendData.subscriptionEnd)) {
                    simpleToast.error("'subscriptionEnd' is not a valid EPOCH");
                    return;
                }
            }

            // languageId
            sendData.languageId = parseInt(formData.get("languageId") as string);

            // roleId
            sendData.roleId = parseInt(formData.get("roleId") as string);

            // password
            if (formData.get("password")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'password'");
                return;
            }
            sendData.password = formData.get("password") as string;

            console.log(sendData);

            RestClient.post<UserUpdateInfo>(
                ServiceBaseURL + "users/",
                sendData,
                setErrorMessage,
                setSuccessMessage,
                "creating user: ",
                "created user"
            );
        }
    }

    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    if (
        (organisations === undefined)
    ) {
        return (
            <>
                <i>loading</i>
            </>
        )
    }

    return (
        <>
            <div className="big-form">
                <form className="big-form" id="createUserForm" onSubmit={handleSubmitCreateUserForm}>
                    <table className="full-width big-form">
                        <tbody className="big-form">

                        {/*email*/}
                        <tr>
                            <td valign="top"><label htmlFor='email'>EMail</label></td>
                            <td>
                                <input id='email' name='email' size={120} />
                            </td>
                        </tr>

                        {/*lastName*/}
                        <tr>
                            <td valign="top"><label htmlFor='lastName'>Last Name</label></td>
                            <td>
                                <input id='lastName' name='lastName' size={120} />
                            </td>
                        </tr>

                        {/*firstName*/}
                        <tr>
                            <td valign="top"><label htmlFor='firstName'>First Name</label></td>
                            <td>
                                <input id='firstName' name='firstName' size={120} />
                            </td>
                        </tr>

                        {/*enabled*/}
                        <tr>
                            <td valign="top"><label htmlFor='enabled'>Enabled</label></td>
                            <td>
                                <select name="enabled" id="enabled">
                                    <option value={0}>false</option>
                                    <option value={1}>true</option>
                                </select>
                            </td>
                        </tr>

                        {/*organisation*/}
                        <tr>
                            <td valign="top"><label htmlFor='organisationId'>Organisation</label></td>
                            <td>
                                <select name="organisationId" id="organisationId">
                                    <option value={-1}>-- no organisation --</option>
                                    {organisations?.map((organisation: Organisation) =>
                                        <option value={organisation.id}>{organisation.name}</option>
                                    )}
                                </select>
                            </td>
                        </tr>

                        {/*phone*/}
                        <tr>
                            <td valign="top"><label htmlFor='phone'>Phone</label></td>
                            <td>
                                <input id='phone' name='phone' size={120}/>
                            </td>
                        </tr>

                        {/*subscriptionStart*/}
                        <tr>
                            <td valign="top"><label htmlFor='subscriptionStart'>Subscription Start</label></td>
                            <td>
                                <input id='subscriptionStart' name='subscriptionStart' size={120}/>
                            </td>
                        </tr>

                        {/*subscriptionEnd*/}
                        <tr>
                            <td valign="top"><label htmlFor='subscriptionEnd'>Subscription End</label></td>
                            <td>
                                <input id='subscriptionEnd' name='subscriptionEnd' size={120}/>
                            </td>
                        </tr>

                        {/*language*/}
                        <tr>
                            <td valign="top"><label htmlFor='languageId'>Language</label></td>
                            <td>
                                <select name="languageId" id="languageId">
                                    <option value={LanguageEnum.DE}>DE</option>
                                    <option value={LanguageEnum.EN}>EN</option>
                                </select>
                            </td>
                        </tr>

                        {/*role*/}
                        <tr>
                            <td valign="top"><label htmlFor='roleId'>Role</label></td>
                            <td>
                                <select name="roleId" id="roleId">
                                    <option value={RoleEnum.STUDENT}>Student</option>
                                    <option value={RoleEnum.MANAGER}>Manager</option>
                                    <option value={RoleEnum.INSTRUCTOR}>Instructor</option>
                                </select>
                            </td>
                        </tr>

                        {/*password*/}
                        <tr>
                            <td valign="top"><label htmlFor='password'>Password</label></td>
                            <td>
                                <input id='password' name='password' size={120}/>
                            </td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <button type="submit">Create User</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>

        </>
    )

}
