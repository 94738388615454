import React from "react";
import {Link, Navigate} from "react-router-dom";
import {store} from "../../store";
import UserSession from "../../auth/UserSession";
import Menu from "../../components/Menu";
import {RoleEnum, RoleEnumHelperInstance} from "../enums/RoleEnum";
import {LanguageEnumHelperInstance} from "../enums/LanguageEnum";

export default function ProfilePage() {

    let userSession = store.getState().session as UserSession | undefined;

    if (userSession === undefined) {
        return (
            <>
                <Navigate to="/"/>
            </>
        );
    }

    return (
        <>
            <Menu/>

            <h1>My Profile</h1>

            <table>
                <tbody>
                {/*<tr>*/}
                {/*    <td><b>token</b></td>*/}
                {/*    <td>{userSession.token}</td>*/}
                {/*</tr>*/}

                <tr>
                    <td><b>EMail</b></td>
                    <td>{userSession.email}</td>
                </tr>

                <tr>
                    <td><b>Name</b></td>
                    <td>{userSession.firstName} {userSession.lastName}</td>
                </tr>

                <tr>
                    <td><b>Role</b></td>
                    <td>{RoleEnumHelperInstance.forId(userSession.roleId)}</td>
                </tr>
                <tr>
                    <td><b>Language</b></td>
                    <td>{LanguageEnumHelperInstance.forId(userSession.languageId)}</td>
                </tr>
                </tbody>
            </table>
            <p/>
            <Link className={"menu-link"} to={"/profile/edit"}>Edit My Profile</Link>

        </>
    )


}