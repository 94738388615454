import {Link} from "react-router-dom";
import React from "react";
import {store} from "../store";
import UserSession from "../auth/UserSession";
import axios from "axios";
import {RoleEnum} from "../service/enums/RoleEnum";

export const AUTH_TOKEN_PREFIX = "kapla-auth-token: ";

function menu_notLoggedOn() {
    axios.defaults.headers.common['Authorization'] = undefined;
    return (
        <>
            <p/>
            <b>Not Logged In</b>
            <p/>
            <Link className={"menu-link"} to={"/login"}>Login</Link>
            <Link className={"menu-link"} to={"/"}>Home</Link>
            <p/>
            <hr/>
            <p/>
        </>
    );
}

function menu_section_home(isAdmin: boolean, isStudent: boolean): JSX.Element {
    return (
        <>
            <td className={"menu-element"}>
                <table className={"menu-element"}>
                    <tbody className={"menu-element"}>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/"}>Home</Link>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </>
    )
}

function menu_section_courses(isAdmin: boolean, isStudent: boolean): JSX.Element {
    return (
        <>
            <td className={"menu-element"}>
                <table className={"menu-element"}>
                    <tbody className={"menu-element"}>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/courses/search"}>Search Courses</Link>
                        </td>
                    </tr>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/courses/list"}>List Courses</Link>
                        </td>
                    </tr>
                    {isAdmin ?
                        <tr className={"menu-element"}>
                            <td className={"menu-element"} valign={"top"}>
                                <Link className={"menu-link"} to={"/courses/create"}>Create Course</Link>
                            </td>
                        </tr>
                        : ""}
                    {isStudent ?
                        <tr className={"menu-element"}>
                            <td className={"menu-element"} valign={"top"}>
                                <Link className={"menu-link"} to={"/my/courses/list"}>My Courses</Link>
                            </td>
                        </tr>
                        : ""}
                    </tbody>
                </table>
            </td>
        </>
    )
}

function menu_section_courseTags(isAdmin: boolean, isStudent: boolean): JSX.Element {
    return (
        <>
            <td className={"menu-element"}>
                <table className={"menu-element"}>
                    <tbody className={"menu-element"}>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/course_tags/list"}>List Course Tags</Link>
                        </td>
                    </tr>
                    {isAdmin ?
                        <tr className={"menu-element"}>
                            <td className={"menu-element"} valign={"top"}>
                                <Link className={"menu-link"} to={"/course_tags/create"}>Create Course Tag</Link>
                            </td>
                        </tr>
                        : ""}
                    </tbody>
                </table>
            </td>
        </>
    )
}

function menu_section_courseCategories(isAdmin: boolean, isStudent: boolean): JSX.Element {
    return (
        <>
            <td className={"menu-element"}>
                <table className={"menu-element"}>
                    <tbody className={"menu-element"}>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/course_categories/list"}>List Course Categories</Link>
                        </td>
                    </tr>
                    {isAdmin ?
                        <tr className={"menu-element"}>
                            <td className={"menu-element"} valign={"top"}>
                                <Link className={"menu-link"} to={"/course_categories/create"}>Create Course
                                    Category</Link>
                            </td>
                        </tr>
                        : ""}
                    </tbody>
                </table>
            </td>
        </>
    )
}

function menu_section_skillGroups(isAdmin: boolean, isStudent: boolean): JSX.Element {
    return (
        <>
            <td className={"menu-element"}>
                <table className={"menu-element"}>
                    <tbody className={"menu-element"}>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/skill_groups/list"}>List Skill Groups</Link>
                        </td>
                    </tr>
                    {isAdmin ?
                        <tr className={"menu-element"}>
                            <td className={"menu-element"} valign={"top"}>
                                <Link className={"menu-link"} to={"/skill_groups/create"}>Create Skill Group</Link>
                            </td>
                        </tr>
                        : ""}
                    </tbody>
                </table>
            </td>
        </>
    )
}

function menu_section_skills(isAdmin: boolean, isStudent: boolean): JSX.Element {
    return (
        <>
            <td className={"menu-element"}>
                <table className={"menu-element"}>
                    <tbody className={"menu-element"}>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/skills/list"}>List Skills</Link>
                        </td>
                    </tr>
                    {isAdmin ?
                        <tr className={"menu-element"}>
                            <td className={"menu-element"} valign={"top"}>
                                <Link className={"menu-link"} to={"/skills/create"}>Create Skills</Link>
                            </td>
                        </tr>
                        : ""}
                    {isStudent ?
                        <tr className={"menu-element"}>
                            <td className={"menu-element"} valign={"top"}>
                                <Link className={"menu-link"} to={"/my/skills/list"}>My Skills</Link>
                            </td>
                        </tr>
                        : ""}
                    </tbody>
                </table>
            </td>
        </>
    )
}

function menu_section_organisations(isAdmin: boolean, isStudent: boolean): JSX.Element {

    // admin only section
    if (!isAdmin) {
        return (<></>);
    }

    return (
        <>
            <td className={"menu-element"}>
                <table className={"menu-element"}>
                    <tbody className={"menu-element"}>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/organisations/list"}>List Organisations</Link>
                        </td>
                    </tr>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/organisations/create"}>Create Organisation</Link>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </>
    )
}

function menu_section_users(isAdmin: boolean, isStudent: boolean): JSX.Element {

    // admin only section
    if (!isAdmin) {
        return (<></>);
    }

    return (
        <>
            <td className={"menu-element"}>
                <table className={"menu-element"}>
                    <tbody className={"menu-element"}>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/users/list"}>List Users</Link>
                        </td>
                    </tr>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/users/create"}>Create User</Link>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </>
    )
}

function menu_section_profile(isAdmin: boolean, isStudent: boolean): JSX.Element {
    return (
        <>
            <td className={"menu-element"}>
                <table className={"menu-element"}>
                    <tbody className={"menu-element"}>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/profile/view"}>My Profile</Link>
                        </td>
                    </tr>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/profile/change_password"}>Change Password</Link>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </>
    )
}

function menu_section_logout(isAdmin: boolean, isStudent: boolean): JSX.Element {
    return (
        <>
            <td className={"menu-element"}>
                <table className={"menu-element"}>
                    <tbody className={"menu-element"}>
                    <tr className={"menu-element"}>
                        <td className={"menu-element"} valign={"top"}>
                            <Link className={"menu-link"} to={"/logout"}>Logout</Link>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </td>
        </>
    )
}

/*
 *****************************************************************************************************************************************
 */

export default function Menu() {

    let userSession = store.getState().session as UserSession | undefined;

    if ((userSession === undefined) || (userSession === null)) {
        return menu_notLoggedOn();
    }

    axios.defaults.headers.common['Authorization'] = AUTH_TOKEN_PREFIX + userSession.token;

    const isAdmin: boolean = (userSession.roleId === RoleEnum.ADMIN);
    const isStudent: boolean = (userSession.roleId === RoleEnum.STUDENT);

    return (
        <>
            <p/>
            <b>Logged In as {userSession.firstName} {userSession.lastName} ({userSession.email})</b>
            <p/>

            <table className={"menu-element"} border={1}>
                <thead>
                <tr className={"menu-element"}>
                    <th>Home</th>
                    <th>Courses</th>
                    <th>Course Tags</th>
                    <th>Course Categories</th>
                    <th>Skill Groups</th>
                    <th>Skills</th>
                    {isAdmin ? <th>Organisations</th> : ""}
                    {isAdmin ? <th>Users</th> : ""}
                    <th>Profile</th>
                    <th>Logout</th>
                </tr>
                </thead>
                <tbody className={"menu-element"}>
                <tr className={"menu-element"}>
                    {menu_section_home(isAdmin, isStudent)}
                    {menu_section_courses(isAdmin, isStudent)}
                    {menu_section_courseTags(isAdmin, isStudent)}
                    {menu_section_courseCategories(isAdmin, isStudent)}
                    {menu_section_skillGroups(isAdmin, isStudent)}
                    {menu_section_skills(isAdmin, isStudent)}
                    {menu_section_organisations(isAdmin, isStudent)}
                    {menu_section_users(isAdmin, isStudent)}
                    {menu_section_profile(isAdmin, isStudent)}
                    {menu_section_logout(isAdmin, isStudent)}
                </tr>
                </tbody>
            </table>

            <p/>
            <hr/>
            <p/>
        </>
    )

}

