import React from "react";
import simpleToast from "../../core/SimpleToast";
import CourseModule, {createCourseModule} from "../../types/CourseModule";
import Menu, {AUTH_TOKEN_PREFIX} from "../../../components/Menu";
import {useParams} from "react-router-dom";
import {RestClient} from "../../core/RestClient";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import axios from "axios";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

export default function CourseModulesEditPage() {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;
    axios.defaults.headers.common['Authorization'] = AUTH_TOKEN_PREFIX + userSession?.token;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    const [courseModule, setCourseModule] = React.useState<CourseModule | undefined>(undefined);

    React.useEffect(() => {
        console.log("React.useEffect()");

        RestClient.get<CourseModule>(
            ServiceBaseURL + "course_modules/" + id,
            setErrorMessage,
            setSuccessMessage,
            setCourseModule,
            "loading course module: "
        );

    }, [successMessage]);


    function formToEntity(form: HTMLFormElement): CourseModule | string {

        let formData = new FormData(form);
        let entity: CourseModule = createCourseModule();

        console.log(formData);

        // name
        if (!formData.has("name")) {
            return "missing 'name'";
        }
        if ("" === (formData.get("name") as string)) {
            return "empty 'name'";
        }
        entity.name = formData.get("name") as string;

        // sequence
        if (!formData.has("sequence")) {
            return "missing 'sequence'";
        }
        if ("" === (formData.get("sequence") as string)) {
            return "empty 'sequence'";
        }
        entity.sequence = parseInt(formData.get("sequence") as string);
        if (isNaN(entity.sequence)) {
            return "'sequence' is not a valid number";
        }

        // descriptionShort
        if (formData.has("descriptionShort")) {
            if ("" === (formData.get("descriptionShort") as string)) {
                entity.descriptionShort = undefined;
            } else {
                entity.descriptionShort = formData.get("descriptionShort") as string;
            }
        }

        // descriptionShort
        if (formData.has("descriptionLong")) {
            if ("" === (formData.get("descriptionLong") as string)) {
                entity.descriptionLong = undefined;
            } else {
                entity.descriptionLong = formData.get("descriptionLong") as string;
            }
        }

        entity.id = parseInt(id as string);
        entity.courseId = courseModule?.courseId;

        return entity;
    }


    function handleSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();

        console.log("handleSubmit()")

        const form = document.querySelector("form");
        if (form !== null) {

            let entity = formToEntity(form);

            console.log(entity);
            if (typeof entity === "string") {
                console.error("ERROR(3):" + entity as string);
                simpleToast.error(entity as string);
            } else {
                console.log("send");
                // we can send this

                RestClient.put<CourseModule>(
                    ServiceBaseURL + "course_modules/" + id,
                    entity,
                    setErrorMessage,
                    setSuccessMessage,
                    "updating course module: ",
                    "updated course module: "
                );
            }

        }
    }

    function showEditFormContent(courseModule: CourseModule) {

        let value_name = "";
        if ((courseModule.name !== undefined)) {
            value_name = courseModule.name as string;
        }

        let value_sequence = "";
        if ((courseModule.sequence !== undefined)) {
            let value_sequence_int = courseModule.sequence as number;
            value_sequence = value_sequence_int.toString();
        }

        return (
            <>
                <tr>
                    <td valign="top"><label htmlFor='name'>name</label></td>
                    <td><input id='name' name='name' size={120} defaultValue={value_name}/></td>
                </tr>
                <tr>
                    <td valign="top"><label htmlFor='sequence'>sequence</label></td>
                    <td><input id='sequence' name='sequence' size={120} defaultValue={value_sequence}/></td>
                </tr>
                <tr>
                    <td valign="top"><label htmlFor='descriptionShort'>Short Description</label></td>
                    <td><input id='descriptionShort' name='descriptionShort' size={120}
                               defaultValue={courseModule.descriptionShort}/></td>
                </tr>
                <tr>
                    <td valign="top"><label htmlFor='descriptionLong'>Long Description</label></td>
                    <td><textarea id='descriptionLong'
                                  name='descriptionLong'>{courseModule.descriptionLong}</textarea></td>
                </tr>
            </>
        )
    }

    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    if (
        (courseModule === undefined)
    ) {
        return (
            <>
                <i>loading</i>
            </>
        )
    }


    return (
        <>
            <Menu/>
            <h1>Edit CourseModule</h1>
            <div className="big-form">
                <form className="big-form" id="formId" onSubmit={handleSubmit}>
                    <input type="hidden" id="timezone" name="timezone" value="Europe:Berlin"/>
                    <table className="full-width big-form">
                        <tbody className="big-form">
                        {showEditFormContent(courseModule as CourseModule)}
                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <button type="submit">Update CourseModule</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </>
    )

}
