import React from "react";
import SkillsListPageInner from "./SkillsListPageInner";
import Menu from "../../../components/Menu";

export default function SkillsListPage() {

    return (
        <>
            <Menu/>
            <h1>Skills</h1>
            <SkillsListPageInner />
        </>
    )

}
