
export default interface CourseCourseCategory {
    courseId: number | undefined;
    courseCategoryId: number | undefined;
}

export function createCourseCourseCategory(): CourseCourseCategory {
    return {
        courseId: undefined,
        courseCategoryId: undefined
    };
}
