import React from "react";
import Menu from "../../components/Menu";

export default function HomePage() {

    return (
        <>
            <Menu />
            <h1>Home</h1>
            <div id={"welcome-message"} className={"message"}>Welcome to Karriere-Plattform</div>
        </>


    );

}

