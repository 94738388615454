import React from "react";
import {useParams} from "react-router-dom";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import simpleToast from "../../core/SimpleToast";
import UserInfo from "../../types/UserInfo";
import {RestClient} from "../../core/RestClient";
import {RoleEnumHelperInstance} from "../../enums/RoleEnum";
import {LanguageEnum} from "../../enums/LanguageEnum";
import Organisation from "../../types/Organisation";
import PasswordUpdateInfo, {createPasswordUpdateInfo} from "../../types/PasswordUpdateInfo";
import UserUpdateInfo, {createUserUpdateInfo} from "../../types/UserUpdateInfo";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

export default function CoursesEditPageInner(): JSX.Element {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    const [userInfo, setUserInfo] = React.useState<UserInfo | undefined>(undefined);
    const [organisations, setOrganisations] = React.useState<Organisation[] | undefined>(undefined);

    //

    React.useEffect(() => {

        RestClient.get<UserInfo>(
            ServiceBaseURL + "user_infos/" + id,
            setErrorMessage,
            undefined,
            setUserInfo,
            "loading user: "
        );

        RestClient.get<Organisation[]>(
            ServiceBaseURL + "organisations/",
            setErrorMessage,
            undefined,
            setOrganisations,
            "loading organisations: "
        );

    }, [successMessage]);


    /*
    ****************************************************************************************************************************************************
     */

    function handleSubmitUpdateUserForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#updateUserForm") as HTMLFormElement;
        if (form !== null) {
            let sendData: UserUpdateInfo = createUserUpdateInfo();

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            // lastName
            if (formData.get("lastName")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'lastName'");
                return;
            }
            sendData.lastName = formData.get("lastName") as string;

            // firstName
            if (formData.get("firstName")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'firstName'");
                return;
            }
            sendData.firstName = formData.get("firstName") as string;

            // enabled
            sendData.enabled = (formData.get("enabled") === "1");

            // organisationId
            sendData.organisationId = parseInt(formData.get("organisationId") as string);
            if (-1 === sendData.organisationId) {
                sendData.organisationId = undefined;
            }

            // phone
            if (formData.get("phone")?.toString().trim().length !== 0) {
                sendData.phone = formData.get("phone") as string;
            }

            // subscriptionStart
            if (formData.get("subscriptionStart")?.toString().trim().length !== 0) {
                sendData.subscriptionStart = parseInt(formData.get("subscriptionStart")?.toString().trim() as string);
                if (isNaN(sendData.subscriptionStart)) {
                    simpleToast.error("'subscriptionStart' is not a valid EPOCH");
                    return;
                }
            }

            // subscriptionEnd
            if (formData.get("subscriptionEnd")?.toString().trim().length !== 0) {
                sendData.subscriptionEnd = parseInt(formData.get("subscriptionEnd")?.toString().trim() as string);
                if (isNaN(sendData.subscriptionEnd)) {
                    simpleToast.error("'subscriptionEnd' is not a valid EPOCH");
                    return;
                }
            }

            // languageId
            sendData.languageId = parseInt(formData.get("languageId") as string);

            console.log(sendData);

            simpleToast.success("sending data ...");

            RestClient.put<UserUpdateInfo>(
                ServiceBaseURL + "users/" + id,
                sendData,
                setErrorMessage,
                setSuccessMessage,
                "updating user: ",
                "updated user"
            );


        }
    }

    function handleSubmitUpdateUserPasswordForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#updateUserPasswordForm") as HTMLFormElement;
        if (form !== null) {
            let sendData: PasswordUpdateInfo = createPasswordUpdateInfo();

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            // password
            if (formData.get("new_password")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'new_password'");
                return;
            }
            sendData.newPassword = formData.get("new_password") as string;

            RestClient.put<PasswordUpdateInfo>(
                ServiceBaseURL + "password_infos/" + id,
                sendData,
                setErrorMessage,
                setSuccessMessage,
                "updating password: ",
                "updated password"
            );
        }
    }

    /*
    ****************************************************************************************************************************************************
     */

    function showUpdateUserForm() {
        return (
            <>
                <div className="big-form">
                    <form className="big-form" id="updateUserForm" onSubmit={handleSubmitUpdateUserForm} >
                        <table className="full-width big-form">
                            <tbody className="big-form">

                            {/*email*/}
                            <tr>
                                <td valign="top"><label htmlFor='email'>EMail</label></td>
                                <td><input id='email' name='email'  size={120} defaultValue={userInfo?.email} readOnly={true} /></td>
                            </tr>

                            {/*lastName*/}
                            <tr>
                                <td valign="top"><label htmlFor='lastName'>Last Name</label></td>
                                <td><input id='lastName' name='lastName' size={120} defaultValue={userInfo?.lastName}/></td>
                            </tr>

                            {/*firstName*/}
                            <tr>
                                <td valign="top"><label htmlFor='firstName'>First Name</label></td>
                                <td><input id='firstName' name='firstName' size={120} defaultValue={userInfo?.firstName}/></td>
                            </tr>

                            {/*enabled*/}
                            <tr>
                                <td valign="top"><label htmlFor='enabled'>Enabled</label></td>
                                <td>
                                    <select name="enabled" id="enabled">
                                        <option value={0} selected={false === userInfo?.enabled}>false</option>
                                        <option value={1} selected={true === userInfo?.enabled}>true</option>
                                    </select>
                                </td>
                            </tr>

                            {/*organisation*/}
                            <tr>
                                <td valign="top"><label htmlFor='organisationId'>Organisation</label></td>
                                <td>
                                    <select name="organisationId" id="organisationId">
                                        <option value={-1} selected={userInfo?.organisationId === undefined}>-- no organisation --</option>
                                        {organisations?.map((organisation: Organisation) =>
                                            <option value={organisation.id} selected={organisation.id === userInfo?.organisationId}>{organisation.name}</option>
                                        )}
                                    </select>
                                </td>
                            </tr>

                            {/*phone*/}
                            <tr>
                                <td valign="top"><label htmlFor='phone'>Phone</label></td>
                                <td><input id='phone' name='phone' size={120} defaultValue={userInfo?.phone}/></td>
                            </tr>

                            {/*subscriptionStart*/}
                            <tr>
                                <td valign="top"><label htmlFor='subscriptionStart'>Subscription Start</label></td>
                                <td><input id='subscriptionStart' name='subscriptionStart' size={120} defaultValue={userInfo?.subscriptionStart}/></td>
                            </tr>

                            {/*subscriptionEnd*/}
                            <tr>
                                <td valign="top"><label htmlFor='subscriptionEnd'>Subscription End</label></td>
                                <td><input id='subscriptionEnd' name='subscriptionEnd' size={120} defaultValue={userInfo?.subscriptionEnd}/></td>
                            </tr>

                            {/*language*/}
                            <tr>
                                <td valign="top"><label htmlFor='languageId'>Language</label></td>
                                <td>
                                    <select name="languageId" id="languageId">
                                        <option value={LanguageEnum.DE} selected={LanguageEnum.DE === userInfo?.languageId}>DE</option>
                                        <option value={LanguageEnum.EN} selected={LanguageEnum.EN === userInfo?.languageId}>EN</option>
                                    </select>
                                </td>
                            </tr>

                            {/*role*/}
                            <tr>
                                <td valign="top"><label htmlFor='role'>New Password</label></td>
                                <td><input id='role' name='role' size={120}
                                           defaultValue={RoleEnumHelperInstance.forId(userInfo?.roleId)}
                                           readOnly={true}/></td>
                            </tr>

                            <tr>
                            <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Update User</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

            </>
        )

    }

    function showUpdateUserPasswordForm() {
        return (
            <>
                <div className="big-form">
                    <form className="big-form" id="updateUserPasswordForm" onSubmit={handleSubmitUpdateUserPasswordForm} >
                        <table className="full-width big-form">
                            <tbody className="big-form">
                            <tr>
                                <td valign="top"><label htmlFor='new_password'>New Password</label></td>
                                <td><input /* type={"password"} */ id='new_password' name='new_password' size={120}/></td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Update Password</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

            </>
        )

    }


    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    if (
        (userInfo === undefined) ||
        (organisations === undefined)
    ) {
        return (
            <>
                <i>loading</i>
            </>
        )
    }

    return (
        <>
            <h2>User Details</h2>
            {showUpdateUserForm()}
            <h2>Update User Password</h2>
            {showUpdateUserPasswordForm()}
        </>
    )

}
