
export enum LanguageEnum {
    EN = 0,
    DE = 1,
}

export class LanguageEnumHelper {

    public forId(languageId: number | undefined): string | undefined {
        if (languageId === undefined) {
            return undefined;
        }

        switch (languageId) {
            case LanguageEnum.EN:
                return "EN";
            case LanguageEnum.DE:
                return "DE";

            default:
                return "unknown";
        }


    }
}

export const LanguageEnumHelperInstance = new LanguageEnumHelper();

