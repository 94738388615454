import React from "react";
import toast from "react-hot-toast";
import axios, {AxiosError, AxiosResponse} from "axios";
import {Link, useParams} from "react-router-dom";
import CourseModuleContent from "../../types/CourseModuleContent";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import RestResponseWrapper from "../../core/RestResponseWrapper";
import {RoleEnum} from "../../enums/RoleEnum";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

const ENTITY_NAME_SINGULAR: string = "CourseModuleContent";
const ENTITY_NAME_PLURAL: string = "CourseModuleContents";
const RESOURCE_URL: string = ServiceBaseURL + "course_module_contents/";

export default function CourseModuleContentsViewPageInner() {

    const {id} = useParams();

    console.log("CourseModuleContentsViewPageInner (start)");

    const [error, setError] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(true);
    const [result, setResult] = React.useState<CourseModuleContent | undefined>(undefined);

    const [trigger, setTrigger] = React.useState<number>(0);

    let userSession = store.getState().session as UserSession | undefined;

    React.useEffect(() => {

        console.log("useEffect()");

        axios.get(RESOURCE_URL + id)
            .then((responseObject: AxiosResponse) => {
                console.log(responseObject);
                const restResult: RestResponseWrapper<CourseModuleContent> = responseObject.data;
                if (restResult.errorMessage !== null) {
                    setLoading(false);
                    toast.error("load failed: " + restResult.errorMessage, {
                        id: 'error1',
                    });
                    console.log("load failed: " + restResult.errorMessage);
                } else {
                    if ((restResult.response === null) || (restResult.response === undefined)) {
                        setResult(undefined);
                        setLoading(false);
                        console.log("no such element");
                    } else {
                        setResult(restResult.response as CourseModuleContent);
                        setLoading(false);
                        console.log("load successful");
                    }
                }
            })
            .catch((errorObject: AxiosError | Error) => {
                if (axios.isAxiosError(errorObject)) {
                    let axiosError = errorObject as AxiosError;
                    if (axiosError.code === 'ERR_NETWORK') {
                        toast.error("network error: is the service running?", {
                            id: 'error2',
                        });
                        console.log("network error: is the service running?")
                    } else {
                        toast.error(axiosError.message, {
                            id: 'error3',
                        });
                        console.log(axiosError.message);
                    }
                } else {
                    toast.error(errorObject.message, {
                        id: 'error4',
                    });
                    console.log(errorObject.message);
                }
                setLoading(false);
            });

    }, [trigger]);

    if (loading) {
        return <b>LOADING</b>;
    }

    if (error) {
        toast.error(error, {
            id: 'error1',
        });
        return <div className='error-box'>{error}</div>;
    }

    function showContent(courseModuleContent : CourseModuleContent) {

        if (result === undefined) {
            return (
                <b>No such {ENTITY_NAME_SINGULAR}</b>
            )
        }

        return (
            <>
                <table border={1}>
                    <tbody>
                    <tr>
                        <td align={"left"}><b>Sequence</b></td>
                        <td align={"left"}>{courseModuleContent.sequence}</td>
                    </tr>
                    <tr>
                        <td align={"left"}><b>Content Type</b></td>
                        <td align={"left"}>{courseModuleContent.contentType}</td>
                    </tr>
                    <tr>
                        <td align={"left"} valign={"top"}><b>Content</b></td>
                        <td align={"left"}>
                            <pre>{courseModuleContent.content}</pre>
                        </td>
                    </tr>
                    </tbody>
                </table>

                {
                    (userSession?.roleId === RoleEnum.ADMIN
                        ? <><p/>
                            <Link className={"menu-link"}
                                  to={"/course_module_contents/edit/" + courseModuleContent.id}>Edit Content</Link>
                        </>
                        : "")}
            </>)
    }

    return (
        <>
            {showContent(result as CourseModuleContent)}
            <p/>
            <Link className={"menu-link"} to={"/course_modules/view/" + result?.courseModuleId}>Back to Course Module</Link>
        </>
    )

}
