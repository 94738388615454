
export default interface Skill {
    id: number | undefined;
    skillGroupId: number | undefined;
    name: string | undefined;
}

export function createSkill(): Skill {
    return {
        id: undefined,
        skillGroupId: undefined,
        name: undefined
    };
}
