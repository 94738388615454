import React from "react";
import {MapHelperInstance} from "../../core/MapHelper";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import {Link, Navigate} from "react-router-dom";
import {RoleEnum} from "../../enums/RoleEnum";
import Menu from "../../../components/Menu";
import SkillsListPageInner from "../skills/SkillsListPageInner";

export default function MySkillsListPageInner() {

    let userSession = store.getState().session as UserSession | undefined;

    if (userSession === undefined) {
        return (
            <>
                <Navigate to="/"/>
            </>
        )
    }

    if (userSession.roleId !== RoleEnum.STUDENT) {
        return (
            <>
                <b>Your role does not allow course enrollment.</b>
            </>
        )
    }

    return (
        <>
            {<SkillsListPageInner propertyMap={MapHelperInstance.build1('userId', '-1')}/>}{
            (userSession.roleId === RoleEnum.STUDENT)
                ? <>            <p/><Link className={"menu-link"} to={"/my/skills/edit"}>Edit My Skills</Link> </>
                : <></>
        }
        </>
    )

}
