import React from "react";

export class DateTimeUtilClass {

    // const     timestamp:number = require('unix-timestamp');
    //

    EPOCH_HOUR: number = 3600 * 1000;

    /*
     * GLOBAL
     */
    public epochToDateDisplayString(epoch: number | null | undefined): string {

        if ((epoch === undefined) || (epoch === null)) {
            return "No Date";
        }

        return (new Date(epoch).toLocaleDateString('de-DE'));
    }

    public epochToDateTimeDisplayString(epoch: number | null | undefined): string {

        if ((epoch === null) || (epoch === undefined)) {
            return "No Date";
        }

        let date = new Date(epoch);

        return date.toLocaleDateString('de-DE') + " " + date.toLocaleTimeString('de-DE');
    }

    public epochToISO(epoch: number | null | undefined): string | null {

        if ((epoch === undefined) || (epoch === null)) {
            return null;
        }

        return (new Date(epoch)).toJSON();
    }

    /*
     *DATE
     */

    /*
     * DATE-TIME
     */

    public ISOToEpoch(iso: string | null | undefined): number | undefined {

        console.log("DT: '" + iso + "'");
        if ((iso === undefined) || (iso === null) || (iso.length === 0)) {
            console.log("invalid");
            return undefined;
        }

        let dateTime = new Date(iso);
        console.log(dateTime);
        if (dateTime === null) {
            return undefined;
        }

        return dateTime.getTime();
    }

    /*
     * FORM
     */

    public showDateInput(key: string, epoch: number | null): JSX.Element {

        // epoch = null;

        if (epoch === null) {
            return (
                <input type="date" id={key} name={key}/>
            )
        }

        let iso = this.epochToISO(epoch) as string;

        // crop
        iso = iso.slice(0, 10);
        console.log("ISO: " + iso);

        return (
            <>
                <input type="date" id={key} name={key} defaultValue={iso}/>
            </>
        )
    }

    public showDateTimeInput(key: string, epoch: number | null): JSX.Element {

        if (epoch === null) {
            console.log("no pre");
            return (
                <input type="datetime-local" id={key} name={key}/>
            )
        }

        let iso = this.epochToISO(epoch + this.EPOCH_HOUR) as string;

        // crop
        iso = iso.slice(0, 16);
        console.log(iso);

        return (
            <>
                {/*<input type="hidden" id="timezone" name="timezone" value="+01:00"/>*/}
                <input type="datetime-local" id={key} name={key} defaultValue={iso}/>
            </>
        )
    }

}

const DateTimeUtil = new DateTimeUtilClass();
export default DateTimeUtil;

