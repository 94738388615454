import axios, {AxiosError, AxiosResponse} from "axios";
import RestResponseWrapper from "./RestResponseWrapper";

export default class RestClientClass {

    public post<S>(
        url: string,
        sendData: S,
        errorMessageSetter: React.Dispatch<React.SetStateAction<string | undefined>>,
        successMessageSetter: React.Dispatch<React.SetStateAction<string | undefined>> | undefined,
        errorMessagePrefix: string | undefined = undefined,
        successMessage: string | undefined = undefined
    ) {

        console.log("RestClient:post()");
        console.log("url: '" + url + "'");
        console.log("data:");
        console.log(sendData);

        axios.post(url, sendData)
            .then((axiosResponse: AxiosResponse) => {
                console.log("RestClient:get() => .then")
                const restResultWrapper: RestResponseWrapper<void> = axiosResponse.data;
                console.log(restResultWrapper);
                if ((restResultWrapper.errorMessage !== null) && (restResultWrapper.errorMessage !== undefined)) {
                    errorMessageSetter(((errorMessagePrefix !== undefined) ? errorMessagePrefix : "") + restResultWrapper.errorMessage);
                    console.log("setError(inner)");
                } else {
                    if (successMessageSetter) {
                        successMessageSetter(successMessage);
                    }
                }
            })
            .catch((errorObject: AxiosError | Error) => {
                console.log("RestClient:get() => .catch")
                console.log(errorObject);
                errorMessageSetter(((errorMessagePrefix !== undefined) ? errorMessagePrefix : "") + errorObject.message)
                console.log("setError(outer)");
            });

    }

    public get<T>(
        url: string,
        errorMessageSetter: React.Dispatch<React.SetStateAction<string | undefined>>,
        successMessageSetter: React.Dispatch<React.SetStateAction<string | undefined>> | undefined,
        resultSetter: React.Dispatch<React.SetStateAction<T | undefined>>,
        errorMessagePrefix: string | undefined = undefined,
        successMessage: string | undefined = undefined
    ) {

        console.log("RestClient:get()");
        console.log("url: '" + url + "'");

        axios.get(url)
            .then((axiosResponse: AxiosResponse) => {
                console.log("RestClient:get() => .then")
                const restResultWrapper: RestResponseWrapper<T> = axiosResponse.data;
                console.log(restResultWrapper);
                if ((restResultWrapper.errorMessage !== null) && (restResultWrapper.errorMessage !== undefined)) {
                    errorMessageSetter(((errorMessagePrefix !== undefined) ? errorMessagePrefix : "") + restResultWrapper.errorMessage);
                    console.log("setError(inner)");
                } else {
                    resultSetter(restResultWrapper.response as T);
                    console.log("setResult()");
                    if (successMessageSetter) {
                        successMessageSetter(successMessage);
                    }
                }
            })
            .catch((errorObject: AxiosError | Error) => {
                console.log("RestClient:get() => .catch")
                console.log(errorObject);
                errorMessageSetter(((errorMessagePrefix !== undefined) ? errorMessagePrefix : "") + errorObject.message)
                console.log("setError(outer)");
            });
    }

    public put<S>(
        url: string,
        sendData: S,
        errorMessageSetter: React.Dispatch<React.SetStateAction<string | undefined>>,
        successMessageSetter: React.Dispatch<React.SetStateAction<string | undefined>> | undefined,
        errorMessagePrefix: string | undefined = undefined,
        successMessage: string | undefined = undefined
    ) {

        console.log("RestClient:put()");
        console.log("url: '" + url + "'");
        console.log("data:");
        console.log(sendData);

        axios.put(url, sendData)
            .then((axiosResponse: AxiosResponse) => {
                console.log("RestClient:get() => .then")
                const restResultWrapper: RestResponseWrapper<void> = axiosResponse.data;
                console.log(restResultWrapper);
                if ((restResultWrapper.errorMessage !== null) && (restResultWrapper.errorMessage !== undefined)) {
                    errorMessageSetter(((errorMessagePrefix !== undefined) ? errorMessagePrefix : "") + restResultWrapper.errorMessage);
                    console.log("setError(inner)");
                } else {
                    if (successMessageSetter) {
                        successMessageSetter(successMessage);
                    }
                }
            })
            .catch((errorObject: AxiosError | Error) => {
                console.log("RestClient:get() => .catch")
                console.log(errorObject);
                errorMessageSetter(((errorMessagePrefix !== undefined) ? errorMessagePrefix : "") + errorObject.message)
                console.log("setError(outer)");
            });

    }

    public delete(
        url: string,
        errorMessageSetter: React.Dispatch<React.SetStateAction<string | undefined>>,
        successMessageSetter: React.Dispatch<React.SetStateAction<string | undefined>> | undefined,
        errorMessagePrefix: string | undefined = undefined,
        successMessage: string | undefined = undefined
    ) {

        console.log("RestClient:get()");
        console.log("url: '" + url + "'");

        axios.delete(url)
            .then((axiosResponse: AxiosResponse) => {
                console.log("RestClient:get() => .then")
                const restResultWrapper: RestResponseWrapper<void> = axiosResponse.data;
                console.log(restResultWrapper);
                if ((restResultWrapper.errorMessage !== null) && (restResultWrapper.errorMessage !== undefined)) {
                    errorMessageSetter(((errorMessagePrefix !== undefined) ? errorMessagePrefix : "") + restResultWrapper.errorMessage);
                    console.log("setError(inner)");
                } else {
                    console.log("setResult()");
                    if (successMessageSetter) {
                        successMessageSetter(successMessage);
                    }
                }
            })
            .catch((errorObject: AxiosError | Error) => {
                console.log("RestClient:get() => .catch")
                console.log(errorObject);
                errorMessageSetter(((errorMessagePrefix !== undefined) ? errorMessagePrefix : "") + errorObject.message)
                console.log("setError(outer)");
            });
    }
}

export const RestClient = new RestClientClass();
