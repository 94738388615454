import React from "react";
import CourseModuleContentsViewPageInner from "./CourseModuleContentsViewPageInner";
import Menu from "../../../components/Menu";

export default function CourseModuleContentsViewPage() {

    return (
        <>
            <Menu/>
            <h1>Course Module Content</h1>
            <CourseModuleContentsViewPageInner />
        </>
    )

}
