import React from "react";
import Menu from "../../../components/Menu";
import CoursesListPageInner from "./CoursesListPageInner";

export default function CoursesListPage() {

    return (
        <>
            <Menu/>
            <h1>Courses</h1>
            <CoursesListPageInner />
        </>
    )

}
