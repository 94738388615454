
export default interface CourseTag {
    id: number | undefined;
    name: string | undefined;
}

export function createCourseTag(): CourseTag {
    return {
        id: undefined,
        name: undefined
    };
}
