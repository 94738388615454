import React from "react";
import {Link, useParams} from "react-router-dom";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import Course from "../../types/Course";
import CourseTag from "../../types/CourseTag";
import {RestClient} from "../../core/RestClient";
import CourseCategory from "../../types/CourseCategory";
import simpleToast from "../../core/SimpleToast";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

export default function CoursesSearchPageInner(): JSX.Element {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    const [courseTags, setCourseTags] = React.useState<CourseTag[] | undefined>(undefined);
    const [courseCategories, setCourseCategories] = React.useState<CourseCategory[] | undefined>(undefined);

    const [courses, setCourses] = React.useState<Course[] | undefined>(undefined);

    //

    React.useEffect(() => {

        RestClient.get<CourseTag[]>(
            ServiceBaseURL + "course_tags",
            setErrorMessage,
            undefined,
            setCourseTags,
            "loading course tags: "
        );

        RestClient.get<CourseCategory[]>(
            ServiceBaseURL + "course_categories",
            setErrorMessage,
            undefined,
            setCourseCategories,
            "loading course categories: "
        );

    }, [successMessage]);


    /*
    ****************************************************************************************************************************************************
     */

    function handleSubmitSearchForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        simpleToast.info("search");

        let form = document.querySelector("#searchForm") as HTMLFormElement;
        if (form !== null) {
            // let sendData: Course = createCourse();

            let formData: FormData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            // construct query string
            let queryString = "?doSearch=1";

            formData.forEach((value, key) => {
                console.log("key: " + key);
                console.log("value: " + value);
                if (("-1" === value) && (("category_id" === key) || ("tag_id" === key))) {
                    // ignore the headers
                } else {
                    queryString += "&" + key + "=" + value;
                }
            });

            console.log("queryString");
            console.log(queryString);

            RestClient.get<Course[]>(
                ServiceBaseURL + "courses/" + queryString,
                setErrorMessage,
                setSuccessMessage,
                setCourses,
                "searching courses :",
                "search complete"
            );
        }
    }

    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    if (
        (courseTags === undefined) ||
        (courseCategories === undefined)
    ) {
        return (
            <>
                <i>loading filters</i>
            </>
        )
    }

    function showResult() {

        if ((courses === undefined) || (courses.length === 0)) {
            return (
                <>
                    No Results
                </>
            )
        }

        return (
            <>
                <table border={1}>
                    <tbody>
                    {courses.map((course: Course) =>
                        <tr>
                            <td align={"left"}>
                                <Link to={"/courses/view/" + course.id}>{course.name}</Link>
                            </td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </>
        )
    }


    return (
        <>
            <h2>Search Criteria</h2>

            <div className="big-form">
                <form className="big-form" id="searchForm" onSubmit={handleSubmitSearchForm}>
                    <table className="full-width big-form">
                        <tbody className="big-form">
                        <tr>
                            <td valign="top"><label htmlFor='category_id'>Category</label></td>
                            <td>
                                <select name="category_id" id="category_id" multiple={true}>
                                    <option value={-1}>-- Please choose --</option>
                                    {courseCategories.map((category: CourseCategory) =>
                                        <option value={category.id}>{category.name}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top"><label htmlFor='tag_id'>Tag</label></td>
                            <td>
                                <select name="tag_id" id="tag_id" multiple={true}>
                                    <option value={-1}>-- Please choose --</option>
                                    {courseTags.map((tag: CourseTag) =>
                                        <option value={tag.id}>{tag.name}</option>
                                    )}
                                </select>
                            </td>
                        </tr>
                        <tr>
                            <td valign="top"><label htmlFor='text'>Text</label></td>
                            <td><input id='text' name='text' size={120}/></td>
                        </tr>
                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <button type="submit">Search Courses</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>

            <h2>Results</h2>
            {showResult()}
        </>
    )

}
