
export default interface CourseUser {
    courseId: number | undefined;
    userId: number | undefined;
    progress: number | undefined;
}

export function createCourseUser(): CourseUser {
    return {
        courseId: undefined,
        userId: undefined,
        progress: undefined
    };
}
