
export default interface Course {
    id: number | undefined;
    name: string | undefined;
}

export function createCourse(): Course {
    return {
        id: undefined,
        name: undefined
    };
}
