import PageProperties from "../../core/PageProperties";
import Menu from "../../../components/Menu";
import CourseTagsListPageInner from "../course_tags/CourseTagsListPageInner";
import React from "react";
import CourseCategoriesListPageInner from "./CourseCategoriesListPageInner";

export default function CourseCategoriesListPage(properties: PageProperties | undefined = undefined) {

    return (
        <>
            <Menu/>
            <h1>Course Categories</h1>
            <CourseCategoriesListPageInner />
        </>
    )

}
