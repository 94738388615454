import React from "react";
import {RestClient} from "../../core/RestClient";
import Skill from "../../types/Skill";
import simpleToast from "../../core/SimpleToast";
import UserSession from "../../../auth/UserSession";
import {store} from "../../../store";
import {useParams} from "react-router-dom";
import UserSkill, {createUserSkill} from "../../types/UserSkill";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

export default function MySkillsEditPageInner(): JSX.Element {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    const [mySkills, setMySkills] = React.useState<Skill[] | undefined>(undefined);
    const [allSkills, setAllSkills] = React.useState<Skill[] | undefined>(undefined);

    //

    React.useEffect(() => {

        RestClient.get<Skill[]>(
            ServiceBaseURL + "skills/?userId=-1",
            setErrorMessage,
            undefined,
            setMySkills,
            "loading my skills: "
        );

        RestClient.get<Skill[]>(
            ServiceBaseURL + "skills",
            setErrorMessage,
            undefined,
            setAllSkills,
            "loading all skills: "
        );


    }, [successMessage]);

    function handleAddSkillForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#addSkillForm") as HTMLFormElement;
        if (form !== null) {

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            let sendData: UserSkill = createUserSkill();

            sendData.userId = -1;

            let skillId: number = parseInt(formData.get("skillId")?.toString() as string);
            if (skillId < 0) {
                simpleToast.error("no entry selected");
                return;
            }
            sendData.skillId = skillId;

            sendData.rating = parseInt(formData.get("rating")?.toString() as string);
            if (isNaN(sendData.rating)) {
                simpleToast.error("'rating' is not a valid number");
                return;
            }

            RestClient.post(
                ServiceBaseURL + "user_skills/",
                sendData,
                setErrorMessage,
                setSuccessMessage,
                "adding skill: ",
                "added skill"
            );

        }
    }

    function handleRemoveSkillForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#removeSkillForm") as HTMLFormElement;
        if (form !== null) {

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            let skillId: number = parseInt(formData.get("skillId")?.toString() as string);
            if (skillId < 0) {
                simpleToast.error("no entry selected");
                return;
            }

            RestClient.delete(
                ServiceBaseURL + "user_skills/-1/" + skillId,
                setErrorMessage,
                setSuccessMessage,
                "removing skill: ",
                "removed skill"
            );

        }
    }

    function showAddSkillForm(skills: Skill[]): JSX.Element {

        if (skills.length === 0) {
            return (
                <b>No skills to add</b>
            )
        }

        return (
            <>
                <div className="big-form">
                    <form className="big-form" id="addSkillForm" onSubmit={handleAddSkillForm}>
                        <table className="full-width big-form">
                            <tbody className="big-form">
                            <tr>
                                <td valign="top"><label htmlFor='skillId'>Skill</label></td>
                                <td>
                                    <select name="skillId" id="skillId">
                                        <option value={-1}>-- Please choose --</option>
                                        {skills.map((skill: Skill) =>
                                            <option value={skill.id}>{skill.name}</option>
                                        )}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td valign="top"><label htmlFor='rating'>Rating</label></td>
                                <td><input id='rating' name='rating' size={20}/></td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Add Skill</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </>
        )

    }

    function showRemoveSkillForm(skills: Skill[]): JSX.Element {

        if (skills.length === 0) {
            return (
                <b>No skills to remove</b>
            )
        }

        return (
            <>
                <div className="big-form">
                    <form className="big-form" id="removeSkillForm" onSubmit={handleRemoveSkillForm}>
                        <table className="full-width big-form">
                            <tbody className="big-form">
                            <tr>
                                <td valign="top"><label htmlFor='skillId'>Skill</label></td>
                                <td>
                                    <select name="skillId" id="skillId">
                                        <option value={-1}>-- Please choose --</option>
                                        {skills.map((skill: Skill) =>
                                            <option value={skill.id}>{skill.name}</option>
                                        )}
                                    </select>

                                </td>
                            </tr>
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                    <button type="submit">Remove Skill</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </>
        )

    }

    function showRemoveSkillsForm(skills: Skill[]): JSX.Element {

        return (
            <>
                showAddSkillsForm()
            </>
        )
    }


    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    if (
        (mySkills === undefined) ||
        (allSkills === undefined)
    ) {
        return (
            <>
                <i>loading</i>
            </>
        )
    }

    return (
        <>
            <h2>Add Skill to Profile</h2>
            {showAddSkillForm(allSkills as Skill[])}
            <hr/>
            <h2>Remove Skill from Profile</h2>
            {showRemoveSkillForm(mySkills as Skill[])}
        </>
    )


}
