import React from "react";
import {Route, Routes} from "react-router-dom";

import LoginPage from "../service/special/LoginPage";
import LogoutPage from "../service/special/LogoutPage";
import HomePage from "../service/special/HomePage";
import NotFoundPage from "../service/special/NotFoundPage";
import ProfilePage from "../service/special/ProfilePage";
import ProfileEditPage from "../service/special/ProfileEditPage";

// ---

import CoursesListPage from "../service/pages/courses/CoursesListPage";
import CoursesSearchPage from "../service/pages/courses/CoursesSearchPage";
import CourseModulesViewPage from "../service/pages/course_modules/CourseModulesViewPage";
import CourseModuleContentsViewPage from "../service/pages/course_module_contents/CourseModuleContentsViewPage";
import CoursesEditPage from "../service/pages/courses/CoursesEditPage";
import CoursesCreatePage from "../service/pages/courses/CoursesCreatePage";
import CoursesViewPage from "../service/pages/courses/CoursesViewPage";
import CourseTagsCreatePage from "../service/pages/course_tags/CourseTagsCreatePage";
import CourseTagsEditPage from "../service/pages/course_tags/CourseTagsEditPage";
import CourseTagsDeletePage from "../service/pages/course_tags/CourseTagsDeletePage";
import CourseTagsListPage from "../service/pages/course_tags/CourseTagsListPage";
import CourseCategoriesCreatePage from "../service/pages/course_categories/CourseCategoriesCreatePage";
import CourseCategoriesEditPage from "../service/pages/course_categories/CourseCategoriesEditPage";
import CourseCategoriesDeletePage from "../service/pages/course_categories/CourseCategoriesDeletePage";
import CourseCategoriesListPage from "../service/pages/course_categories/CourseCategoriesListPage";
import OrganisationsCreatePage from "../service/pages/organisation/OrganisationsCreatePage";
import OrganisationsEditPage from "../service/pages/organisation/OrganisationsEditPage";
import OrganisationsDeletePage from "../service/pages/organisation/OrganisationsDeletePage";
import OrganisationsListPage from "../service/pages/organisation/OrganisationsListPage";
import UsersCreatePage from "../service/pages/users/UsersCreatePage";
import UsersEditPage from "../service/pages/users/UsersEditPage";
import UsersDeletePage from "../service/pages/users/UsersDeletePage";
import UsersListPage from "../service/pages/users/UsersListPage";
import SkillGroupsCreatePage from "../service/pages/skill_groups/SkillGroupsCreatePage";
import SkillGroupsEditPage from "../service/pages/skill_groups/SkillGroupsEditPage";
import SkillGroupsDeletePage from "../service/pages/skill_groups/SkillGroupsDeletePage";
import SkillGroupsListPage from "../service/pages/skill_groups/SkillGroupsListPage";
import SkillsCreatePage from "../service/pages/skills/SkillsCreatePage";
import SkillsEditPage from "../service/pages/skills/SkillsEditPage";
import SkillsDeletePage from "../service/pages/skills/SkillsDeletePage";
import SkillsListPage from "../service/pages/skills/SkillsListPage";
import UpdatePasswordPage from "../service/special/UpdatePasswordPage";
import MyCoursesListPage from "../service/pages/my/MyCoursesListPage";
import MySkillsListPage from "../service/pages/my/MySkillsListPage";
import MySkillsEditPage from "../service/pages/my/MySkillsEditPage";
import UsersViewPage from "../service/pages/users/UsersViewPage";
import CourseModulesEditPage from "../service/pages/course_modules/CourseModulesEditPage";
import CourseModuleContentsEditPage from "../service/pages/course_module_contents/CourseModuleContentsEditPage";
import CourseModuleContentsCreatePage from "../service/pages/course_module_contents/CourseModuleContentsCreatePage";

export default function AppRouter() {

    return (
        <main className='app_main'>
            <Routes>
                <Route path='/' element={<HomePage/>}/>
                <Route path='/login' element={<LoginPage/>}/>
                <Route path='/logout' element={<LogoutPage/>}/>

                {/* profile */}
                <Route path='/profile/view' element={<ProfilePage/>}/>
                <Route path='/profile/edit' element={<ProfileEditPage/>}/>
                <Route path='/profile/change_password' element={<UpdatePasswordPage/>}/>

                {/*-----*/}

                {/* course_categories */}
                <Route path='/course_categories/create' element={<CourseCategoriesCreatePage/>}/>
                <Route path='/course_categories/edit/:id' element={<CourseCategoriesEditPage/>}/>
                <Route path='/course_categories/delete/:id' element={<CourseCategoriesDeletePage/>}/>
                <Route path='/course_categories/list' element={<CourseCategoriesListPage/>}/>

                {/* course_module_contents */}
                <Route path='/course_module_contents/create/:id' element={<CourseModuleContentsCreatePage/>}/>
                <Route path='/course_module_contents/view/:id' element={<CourseModuleContentsViewPage/>}/>
                <Route path='/course_module_contents/edit/:id' element={<CourseModuleContentsEditPage/>}/>

                {/* course_modules */}
                <Route path='/course_modules/view/:id' element={<CourseModulesViewPage/>}/>
                <Route path='/course_modules/edit/:id' element={<CourseModulesEditPage/>} />

                {/* course_tags */}
                <Route path='/course_tags/create' element={<CourseTagsCreatePage/>}/>
                <Route path='/course_tags/edit/:id' element={<CourseTagsEditPage/>}/>
                <Route path='/course_tags/delete/:id' element={<CourseTagsDeletePage/>}/>
                <Route path='/course_tags/list' element={<CourseTagsListPage/>}/>

                {/* courses */}
                <Route path='/courses/create' element={<CoursesCreatePage/>}/>
                <Route path='/courses/view/:id' element={<CoursesViewPage/>}/>
                <Route path='/courses/edit/:id' element={<CoursesEditPage/>}/>
                <Route path='/courses/list' element={<CoursesListPage/>}/>
                <Route path='/courses/search' element={<CoursesSearchPage/>}/>

                {/* my */}
                <Route path='/my/courses/list' element={<MyCoursesListPage/>}/>
                <Route path='/my/skills/list' element={<MySkillsListPage/>}/>
                <Route path='/my/skills/edit' element={<MySkillsEditPage/>}/>

                {/* organisations */}
                <Route path='/organisations/create' element={<OrganisationsCreatePage/>}/>
                <Route path='/organisations/edit/:id' element={<OrganisationsEditPage/>}/>
                <Route path='/organisations/delete/:id' element={<OrganisationsDeletePage/>}/>
                <Route path='/organisations/list' element={<OrganisationsListPage/>}/>

                {/* skill_groups */}
                <Route path='/skill_groups/create' element={<SkillGroupsCreatePage/>}/>
                <Route path='/skill_groups/edit/:id' element={<SkillGroupsEditPage/>}/>
                <Route path='/skill_groups/delete/:id' element={<SkillGroupsDeletePage/>}/>
                <Route path='/skill_groups/list' element={<SkillGroupsListPage/>}/>

                {/* skills */}
                <Route path='/skills/create' element={<SkillsCreatePage/>}/>
                <Route path='/skills/edit/:id' element={<SkillsEditPage/>}/>
                <Route path='/skills/delete/:id' element={<SkillsDeletePage/>}/>
                <Route path='/skills/list' element={<SkillsListPage/>}/>

                {/* users */}
                <Route path='/users/create' element={<UsersCreatePage/>}/>
                <Route path='/users/view/:id' element={<UsersViewPage/>}/>
                <Route path='/users/edit/:id' element={<UsersEditPage/>}/>
                <Route path='/users/delete/:id' element={<UsersDeletePage/>}/>
                <Route path='/users/list' element={<UsersListPage/>} />

                {/*-----*/}

                {/* ... */}
                <Route path='*' element={<NotFoundPage/>}/>
            </Routes>
        </main>
    );
}
