
export default interface UserUpdateInfo {
    lastName: string | undefined;
    firstName: string | undefined;
    enabled: boolean | undefined;
    organisationId: number | undefined;
    phone: string | undefined;
    subscriptionStart: number | undefined;
    subscriptionEnd: number | undefined;
    languageId: number | undefined;
}

export function createUserUpdateInfo(): UserUpdateInfo {
    return {
        enabled: undefined,
        firstName: undefined,
        languageId: undefined,
        lastName: undefined,
        organisationId: undefined,
        phone: undefined,
        subscriptionEnd: undefined,
        subscriptionStart: undefined
    };
}
