import PageProperties from "../../core/PageProperties";
import Menu from "../../../components/Menu";
import MySkillsEditPageInner from "./MySkillsEditPageInner";

export default function MySkillsEditPage(properties: PageProperties | undefined = undefined) {

    return (
        <>
            <Menu />
            {<MySkillsEditPageInner/>}
        </>
    )

}
