export default class MapHelper {

    public build0(): Map<string, string> {
        return new Map<string, string>();
    }

    public build1(
        key1: string, value1: string
        ): Map<string, string> {
        let map = new Map<string, string>();
        map.set(key1, value1);
        return map;
    }

    public build2(
        key1: string, value1: string,
        key2: string, value2: string,
        ): Map<string, string> {
        let map = new Map<string, string>();
        map.set(key1, value1);
        map.set(key2, value2);
        return map;
    }
    public build3(
        key1: string, value1: string,
        key2: string, value2: string,
        key3: string, value3: string,
        ): Map<string, string> {
        let map = new Map<string, string>();
        map.set(key1, value1);
        map.set(key2, value2);
        map.set(key3, value3);
        return map;
    }
    public build4(
        key1: string, value1: string,
        key2: string, value2: string,
        key3: string, value3: string,
        key4: string, value4: string,
        ): Map<string, string> {
        let map = new Map<string, string>();
        map.set(key1, value1);
        map.set(key2, value2);
        map.set(key3, value3);
        map.set(key4, value4);
        return map;
    }

    public build5(
        key1: string, value1: string,
        key2: string, value2: string,
        key3: string, value3: string,
        key4: string, value4: string,
        key5: string, value5: string,
        ): Map<string, string> {
        let map = new Map<string, string>();
        map.set(key1, value1);
        map.set(key2, value2);
        map.set(key3, value3);
        map.set(key4, value4);
        map.set(key5, value5);
        return map;
    }

}

export const MapHelperInstance = new MapHelper();

