import PageProperties from "../../core/PageProperties";
import Menu from "../../../components/Menu";
import UsersCreatePageInner from "./UsersCreatePageInner";

export default function UsersCreatePage(properties: PageProperties | undefined = undefined) {

    return (
        <>
            <Menu />
            <h1>Create User</h1>
            <UsersCreatePageInner/>
        </>
    )

}
