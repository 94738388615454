import toast from "react-hot-toast";

export class SimpleToastClass {

    public info(text : string) : void {
        toast(text, {
            id: Date().toString(),
        });
    }

    public success(text : string) : void {
        toast.success(text, {
            id: Date().toString(),
        });
    }

    public error(text : string) : void {
        toast.error(text, {
            id: Date().toString(),
        });
    }

}

const SimpleToast = new SimpleToastClass();
export default SimpleToast;

