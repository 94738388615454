import React from "react";
import PageProperties from "../../core/PageProperties";
import {Link, useParams} from "react-router-dom";
import {RoleEnumHelperInstance} from "../../enums/RoleEnum";
import {LanguageEnumHelperInstance} from "../../enums/LanguageEnum";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import {RestClient} from "../../core/RestClient";
import simpleToast from "../../core/SimpleToast";
import UserInfo from "../../types/UserInfo";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

export default function UsersViewPageInner(properties: PageProperties | undefined = undefined) {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    const [userInfo, setUserInfo] = React.useState<UserInfo | undefined>(undefined);

    React.useEffect(() => {

        console.log("useEffect()");

        RestClient.get<UserInfo>(
            ServiceBaseURL + "user_infos/" + id,
            setErrorMessage,
            undefined,
            setUserInfo,
            "loading user tags: "
        );
    }, []);

    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    if (
        (userInfo === undefined)
    ) {
        return (
            <>
                <i>loading</i>
            </>
        )
    }

    return (
        <>
            <table border={1}>
                <tbody>
                <tr>
                    <td align={"left"}><b>EMail</b></td>
                    <td align={"left"}>{userInfo.email}</td>
                </tr>
                <tr>
                    <td align={"left"}><b>Last Name</b></td>
                    <td align={"left"}>{userInfo.lastName}</td>
                </tr>
                <tr>
                    <td align={"left"}><b>First Name</b></td>
                    <td align={"left"}>{userInfo.firstName}</td>
                </tr>
                <tr>
                    <td align={"left"}><b>Created</b></td>
                    <td align={"left"}>{userInfo.created}</td>
                </tr>
                <tr>
                    <td align={"left"}><b>Enabled</b></td>
                    <td align={"left"}>{userInfo.enabled?.toString()}</td>
                </tr>
                <tr>
                    <td align={"left"}><b>Organisation</b></td>
                    <td align={"left"}>
                        {(userInfo.organisation)
                            ? <Link
                                to={"/organisations/view/" + userInfo.organisationId}>{userInfo.organisation?.name}</Link>
                            : "no organisation"}
                    </td>
                </tr>
                <tr>
                    <td align={"left"}><b>Phone</b></td>
                    <td align={"left"}>{userInfo.phone}</td>
                </tr>
                <tr>
                    <td align={"left"}><b>Subscription Start</b></td>
                    <td align={"left"}>{userInfo.subscriptionStart}</td>
                </tr>
                <tr>
                    <td align={"left"}><b>Subscription End</b></td>
                    <td align={"left"}>{userInfo.subscriptionEnd}</td>
                </tr>
                <tr>
                    <td align={"left"}><b>Language</b></td>
                    <td align={"left"}>
                        {LanguageEnumHelperInstance.forId(userInfo.languageId)}
                    </td>
                </tr>
                <tr>
                    <td align={"left"}><b>Role</b></td>
                    <td align={"left"}>
                        {RoleEnumHelperInstance.forId(userInfo.roleId)}
                    </td>
                </tr>
                </tbody>
            </table>

            <p/>

            <Link className={"menu-link"} to={"/users/edit/" + userInfo.id}>Edit User</Link>

        </>
    )

}
