import React from "react";
import Menu from "../../components/Menu";


export default function NotFoundPage() {

    return (
        <>
            <Menu/>
            <b>There is no page here</b>
        </>
    );
}
