import UserUpdateInfo from "./UserUpdateInfo";

export default interface UserCreateInfo extends UserUpdateInfo {
    email: string | undefined;
    roleId: number | undefined;
    password: string | undefined;
}

export function createUserCreateInfo(): UserCreateInfo {
    return {
        email: undefined,
        enabled: undefined,
        firstName: undefined,
        languageId: undefined,
        lastName: undefined,
        organisationId: undefined,
        password: undefined,
        phone: undefined,
        roleId: undefined,
        subscriptionEnd: undefined,
        subscriptionStart: undefined
    };
}
