export enum RoleEnum {
    ADMIN = 1,
    MANAGER = 2,
    INSTRUCTOR = 3,
    STUDENT = 4
}

export class RoleEnumHelper {

    public forId(roleId: number | undefined): string | undefined {
        if (roleId === undefined) {
            return undefined;
        }

        switch (roleId) {
            case RoleEnum.ADMIN:
                return "Administrator";
            case RoleEnum.MANAGER:
                return "Manager";
            case RoleEnum.INSTRUCTOR:
                return "Instructor";
            case RoleEnum.STUDENT:
                return "Student";

            default:
                return "unknown";
        }


    }
}

export const RoleEnumHelperInstance = new RoleEnumHelper();

