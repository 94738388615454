import React from "react";
import toast from "react-hot-toast";
import axios, {AxiosError, AxiosResponse} from "axios";
import {Link, useSearchParams} from "react-router-dom";
import PageProperties from "../../core/PageProperties";
import {PageHelperInstance} from "../../core/PageHelper";
import Course from "../../types/Course";
import RestResponseWrapper from "../../core/RestResponseWrapper";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

const ENTITY_NAME_SINGULAR: string = "Course";
const ENTITY_NAME_PLURAL: string = "Courses";
const RESOURCE_URL: string = ServiceBaseURL + "courses/";

export default function CoursesListPageInner(properties: PageProperties | undefined = undefined) {

    console.log("CoursesListPageInner (start)");

    const [error, setError] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(true);
    const [result, setResult] = React.useState<Course[] | undefined>(undefined);

    const [trigger, setTrigger] = React.useState<number>(0);

    const [searchParams] = useSearchParams();
    const query = PageHelperInstance.createQuery(properties, searchParams);
    console.log("query: '" + query + "'")

    React.useEffect(() => {

        console.log("useEffect()");

        console.log("url: " + RESOURCE_URL + query);

        axios.get(RESOURCE_URL + query)
            .then((responseObject: AxiosResponse) => {
                console.log(responseObject);
                const restResult: RestResponseWrapper<Course[]> = responseObject.data;
                if (restResult.errorMessage !== null) {
                    setLoading(false);
                    toast.error("error(1): " + restResult.errorMessage, {
                        id: 'error1',
                    });
                } else {
                    setResult(restResult.response as Course[]);
                    setLoading(false);
                }
            })
            .catch((errorObject: AxiosError | Error) => {
                if (axios.isAxiosError(errorObject)) {
                    let axiosError = errorObject as AxiosError;
                    if (axiosError.code === 'ERR_NETWORK') {
                        toast.error("network error: is the service running?", {
                            id: 'error2',
                        });
                    } else {
                        toast.error("error(3): " + axiosError.message, {
                            id: 'error3',
                        });
                    }
                } else {
                    toast.error("error(4): " + errorObject.message, {
                        id: 'error4',
                    });
                }
                setLoading(false);
            });

    }, [trigger]);

    if (loading) {
        return <b>LOADING</b>;
    }

    if (error) {
        toast.error(error, {
            id: 'error1',
        });
        return <div className='error-box'>{error}</div>;
    }

    function showContent() {

        if ((result === undefined) || (result.length === 0)) {
            return (
                <b>No {ENTITY_NAME_PLURAL}</b>
            )
        }

        return (
            <>
                <table border={1}>
                    <thead>
                    <tr>
                        <th align={"left"}>name</th>
                    </tr>
                    </thead>
                    <tbody>
                    {result.map((course: Course) =>
                        <>
                            <tr>
                                <td align={"left"}>
                                    <Link to={"/courses/view/" + course.id}>{course.name}</Link>
                                </td>
                            </tr>
                        </>
                    )}
                    </tbody>
                </table>
            </>)
    }

    return (
        <>
            {showContent()}
        </>
    )

}
