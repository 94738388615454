import React from "react";
import MySkillsListPageInner from "./MySkillsListPageInner";
import Menu from "../../../components/Menu";

export default function MySkillsListPage() {

    return (
        <>
            <Menu/>
            <h1>My Skills</h1>
            {<MySkillsListPageInner/>}
        </>
    )

}
