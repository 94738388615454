import React from "react";
import axios, {AxiosError, AxiosResponse} from "axios";
import toast from "react-hot-toast";
import UserSession from "../../auth/UserSession";
import Menu from "../../components/Menu";
import {store} from "../../store";
import {ACTION_SET_SESSION} from "../../auth/sessionReducer";
import {Navigate} from "react-router-dom";
import RestResponseWrapper from "../../service/core/RestResponseWrapper";
import {ServiceBaseURL} from "../../components/ServiceConfiguration";

interface Credentials {
    username: string | undefined;
    password: string | undefined;
}

function createCredentials(): Credentials {
    return {
        username: undefined,
        password: undefined
    };
}

export default function LoginPage() {

    const [userSession, setUserSession] = React.useState<UserSession | undefined>(undefined);
    const [isButtonEnabled, setButtonEnabled] = React.useState<boolean>(true);

    React.useEffect(() => {
        console.log("useEffect()");
        store.dispatch({type: ACTION_SET_SESSION, payload: userSession});
    }, [userSession]);

    function handleSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setButtonEnabled(false);

        console.log("handleSubmit()")

        const form: HTMLFormElement | null = document.querySelector("form");
        if (form !== null) {

            const url = ServiceBaseURL + "user_session";
            const sendData = createCredentials();

            console.log("have form content")

            let formData = (new FormData(form));

            // username
            if (formData.has("username")) {
                sendData.username = formData.get("username") as string;
            }

            // password
            if (formData.has("password")) {
                sendData.password = formData.get("password") as string;
            }

            axios.post(url, sendData)
                .then((responseObject: AxiosResponse) => {
                    console.log(responseObject);
                    const result: RestResponseWrapper<UserSession> = responseObject.data;
                    if (result.errorMessage !== null) {
                        setButtonEnabled(true);
                        toast.error("login failed: " + result.errorMessage, {
                            id: 'error1',
                        });
                        console.log("login failed: " + result.errorMessage);
                        setButtonEnabled(true);
                    } else {
                        setUserSession(result.response as UserSession);
                        toast.success("successful logged in", {
                            id: 'success1',
                        });
                        console.log("login successful");
                    }
                })
                .catch((errorObject: AxiosError | Error) => {
                    if (axios.isAxiosError(errorObject)) {
                        let axiosError = errorObject as AxiosError;
                        if (axiosError.code === 'ERR_NETWORK') {
                            toast.error("network error: is the service running?", {
                                id: 'error2',
                            });
                            console.log("network error: is the service running?")
                        } else {
                            toast.error(axiosError.message, {
                                id: 'error3',
                            });
                            console.log(axiosError.message);
                        }
                    } else {
                        toast.error(errorObject.message, {
                            id: 'error4',
                        });
                        console.log(errorObject.message);
                    }
                    setButtonEnabled(true);
                });
        }
    }

    if (userSession !== undefined) {
        return (
            <Navigate to="/"/>
        );
    }

    return (
        <>
            <Menu/>
            <div className={"message"}>Please enter your credentials below</div>
            <div className="login-wrapper">
                <h1>Please Log In</h1>
                <form onSubmit={handleSubmit} id="loginForm">
                    <label>
                        <p>Username</p>
                        <input id='username' name='username' size={120} type="text" required={true}/>
                    </label>
                    <label>
                        <p>Password</p>
                        <input id='password' name='password' size={120} type="password" required={true}/>
                    </label>
                    <div>
                        <button type="submit" disabled={!isButtonEnabled}>Submit</button>
                    </div>
                </form>
            </div>
        </>


    );

}
