import React from "react";
import PageProperties from "../../core/PageProperties";
import Menu from "../../../components/Menu";
import UsersViewPageInner from "./UsersViewPageInner";

export default function UsersViewPage(properties: PageProperties | undefined = undefined) {


    return (
        <>
            <Menu/>
            <h1>User</h1>
            <UsersViewPageInner />
        </>
    )

}
