
export default interface Organisation {
    id: number | undefined;
    name: string | undefined;
}

export function createOrganisation(): Organisation {
    return {
        id: undefined,
        name: undefined
    };
}
