import React from "react";
import Menu from "../../../components/Menu";

export default function CourseTagsDeletePage() {

    return (
        <>
            <Menu />
            <h1>Delete CourseTag</h1>
            <b>Delete CourseTag - disabled for security</b>
        </>
    );

}

