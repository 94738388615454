import React from "react";
import Menu from "../../../components/Menu";
import UsersListPageInner from "./UsersListPageInner";

export default function UsersListPage() {

    return (
        <>
            <Menu/>
            <h1>Users</h1>
            {<UsersListPageInner/>}
        </>
    )

}
