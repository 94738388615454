import React from 'react';
import './App.css';
import AppRouter from "./components/AppRouter";
import {Toaster} from "react-hot-toast";
import axios, {AxiosError} from "axios";
import {store} from "./store";
import {ACTION_CLEAR_SESSION} from "./auth/sessionReducer";

function footer() {
    return (
        <>
            <p/>
            <hr/>
            <p/>
            <b>KaPla 0.1-alpha</b>
        </>
    )
}

export default function App() {

    axios.interceptors.response.use(function (response) {
        return response;
    }, function (error) {
        if (axios.isAxiosError(error)) {
            let axiosError = error as AxiosError;
            console.log("AXIOS ERROR: " + axiosError);
            console.log("AXIOS ERROR STATUS:  " + axiosError.status);
            console.log("AXIOS ERROR MESSAGE: " + axiosError.message);
            console.log("AXIOS ERROR CODE:    " + axiosError.code);

            // if we encountered an 401, we do not have a (valid) auth session, lets clear it
            if (axiosError.message === "Request failed with status code 401") {
                console.log("no valid auth session");
                store.dispatch({type: ACTION_CLEAR_SESSION});
            }
        }

        return Promise.reject(error);
    });

    return (
        <>
            <AppRouter/>
            <Toaster/>
            {footer()}
        </>
    );
}
