
export const ACTION_SET_SESSION = "setSession";
export const ACTION_CLEAR_SESSION = "clearSession";


const initialState  = {
    session: undefined
};
export const sessionReducer = (state = initialState, action) => {
    switch (action.type) {
        case ACTION_SET_SESSION:
            console.log("reducer=>setSession");
            return {
                ...state,
                session: action.payload,
            };
        case ACTION_CLEAR_SESSION:
            console.log("reducer=>clearSession");
            return {
                ...state,
                session: undefined,
            };
        // ... other reducer cases
        default:
            return state;
    }
};
