import React from "react";
import {useParams} from "react-router-dom";
import {store} from "../../store";
import UserSession from "../../auth/UserSession";
import User from "../types/User";
import {RestClient} from "../core/RestClient";
import UserUpdateInfo, {createUserUpdateInfo} from "../types/UserUpdateInfo";
import simpleToast from "../core/SimpleToast";
import {LanguageEnum} from "../enums/LanguageEnum";
import Menu from "../../components/Menu";
import {ServiceBaseURL} from "../../components/ServiceConfiguration";

export default function CoursesEditPageInner(): JSX.Element {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    const [user, setUser] = React.useState<User | undefined>(undefined);

    //

    React.useEffect(() => {

        RestClient.get<User>(
            ServiceBaseURL + "users/-1",
            setErrorMessage,
            undefined,
            setUser,
            "loading user: "
        );
    }, [successMessage]);


    /*
    ****************************************************************************************************************************************************
     */

    function handleSubmitUpdateUserForm(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        let form = document.querySelector("#updateUserForm") as HTMLFormElement;
        if (form !== null) {
            let sendData: UserUpdateInfo = createUserUpdateInfo();

            let formData = new FormData(form);
            console.log("form");
            console.log(form);
            console.log("formData");
            console.log(formData);

            // lastName
            if (formData.get("lastName")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'lastName'");
                return;
            }
            sendData.lastName = formData.get("lastName") as string;

            // firstName
            if (formData.get("firstName")?.toString().trim().length === 0) {
                simpleToast.error("missing or empty 'firstName'");
                return;
            }
            sendData.firstName = formData.get("firstName") as string;

            // phone
            if (formData.get("phone")?.toString().trim().length !== 0) {
                sendData.phone = formData.get("phone") as string;
            }

            // languageId
            sendData.languageId = parseInt(formData.get("languageId") as string);

            console.log(sendData);

            RestClient.put<UserUpdateInfo>(
                ServiceBaseURL + "users/-1",
                sendData,
                setErrorMessage,
                setSuccessMessage,
                "updating user: ",
                "updated user"
            );


        }
    }


    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    if (
        (user === undefined)
    ) {
        return (
            <>
                <i>loading</i>
            </>
        )
    }

    return (
        <>
            <Menu/>
            <h1>Edit Profile</h1>
            <div className="big-form">
                <form className="big-form" id="updateUserForm" onSubmit={handleSubmitUpdateUserForm}>
                    <table className="full-width big-form">
                        <tbody className="big-form">

                        {/*lastName*/}
                        <tr>
                            <td valign="top"><label htmlFor='lastName'>Last Name</label></td>
                            <td><input id='lastName' name='lastName' size={120} defaultValue={user?.lastName}/></td>
                        </tr>

                        {/*firstName*/}
                        <tr>
                            <td valign="top"><label htmlFor='firstName'>First Name</label></td>
                            <td><input id='firstName' name='firstName' size={120} defaultValue={user?.firstName}/>
                            </td>
                        </tr>

                        {/*phone*/}
                        <tr>
                            <td valign="top"><label htmlFor='phone'>Phone</label></td>
                            <td><input id='phone' name='phone' size={120} defaultValue={user?.phone}/></td>
                        </tr>

                        {/*language*/}
                        <tr>
                            <td valign="top"><label htmlFor='languageId'>Language</label></td>
                            <td>
                                <select name="languageId" id="languageId">
                                    <option value={LanguageEnum.DE}
                                            selected={LanguageEnum.DE === user?.languageId}>DE
                                    </option>
                                    <option value={LanguageEnum.EN}
                                            selected={LanguageEnum.EN === user?.languageId}>EN
                                    </option>
                                </select>
                            </td>
                        </tr>

                        <tr>
                            <td>&nbsp;</td>
                            <td>
                                <button type="submit">Update Profile</button>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </form>
            </div>
        </>
    )

}
