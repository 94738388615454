
export default interface CourseCategory {
    id: number | undefined;
    name: string | undefined;
}

export function createCourseCategory(): CourseCategory {
    return {
        id: undefined,
        name: undefined
    };
}
