import React from "react";
import {Link, useParams} from "react-router-dom";
import {store} from "../../../store";
import UserSession from "../../../auth/UserSession";
import Course from "../../types/Course";
import CourseTag from "../../types/CourseTag";
import {RestClient} from "../../core/RestClient";
import CourseCategory from "../../types/CourseCategory";
import simpleToast from "../../core/SimpleToast";
import {RoleEnum} from "../../enums/RoleEnum";
import CourseModulesListPageInner from "../course_modules/CourseModulesListPageInner";
import {MapHelperInstance} from "../../core/MapHelper";
import CourseUser, {createCourseUser} from "../../types/CourseUser";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

export default function CoursesViewPageInner(): JSX.Element {

    const {id} = useParams();
    let userSession = store.getState().session as UserSession | undefined;

    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [successMessage, setSuccessMessage] = React.useState<string | undefined>(undefined);

    const [course, setCourse] = React.useState<Course | undefined>(undefined);
    const [courseTags, setCourseTags] = React.useState<CourseTag[] | undefined>(undefined);
    const [courseCategories, setCourseCategories] = React.useState<CourseCategory[] | undefined>(undefined);

    const [courseUser, setCourseUser] = React.useState<CourseUser | undefined>(undefined);

    //

    React.useEffect(() => {

        console.log("useEffect()");

        RestClient.get<Course>(
            ServiceBaseURL + "courses/" + id,
            setErrorMessage,
            undefined,
            setCourse,
            "loading course: "
        );

        RestClient.get<CourseTag[]>(
            ServiceBaseURL + "course_tags/?courseId=" + id,
            setErrorMessage,
            undefined,
            setCourseTags,
            "loading course tags: "
        );

        RestClient.get<CourseCategory[]>(
            ServiceBaseURL + "course_categories/?courseId=" + id,
            setErrorMessage,
            undefined,
            setCourseCategories,
            "loading course categories: "
        );

        // load enrollment
        if (userSession?.roleId === RoleEnum.STUDENT) {
            RestClient.get<CourseUser>(
                ServiceBaseURL + "course_users/" + id + "/" + userSession.userId,
                setErrorMessage,
                undefined,
                setCourseUser,
                "loading course enrollment: "
            );

        }
    }, [successMessage]);

    function handleEnrollment(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        // enroll

        let sendData: CourseUser = createCourseUser();

        sendData.courseId = parseInt(id as string);
        sendData.userId = userSession?.userId;

        RestClient.post<CourseUser>(
            ServiceBaseURL + "course_users/",
            sendData,
            setErrorMessage,
            setSuccessMessage,
            "enrolling in course",
            "enrolled in course"
        );
    }

    function handleDisEnrollment(event: { preventDefault: () => void; }) {
        event.preventDefault();

        setErrorMessage(undefined);
        setSuccessMessage(undefined);

        // dis-enroll
        RestClient.delete(
            ServiceBaseURL + "course_users/" + id + "/" + userSession?.userId,
            setErrorMessage,
            setSuccessMessage,
            "dis-enrolling from course",
            "dis-enrolled from course"
        );
    }


    function showEnrollmentButton() {
        if (userSession?.roleId !== RoleEnum.STUDENT) {
            return (<></>);
        }

        if (courseUser) {
            return (
                <>
                    <p/>
                    <form className="big-form" id="disEnrollForm" onSubmit={handleDisEnrollment}>
                        <button className={"menu-link"}
                                type="submit">Dis-Enroll from this Course
                        </button>
                    </form>
                </>
            )
        }

        return (
            <>
                <p/>
                <form className="big-form" id="disEnrollForm" onSubmit={handleEnrollment}>
                    <button className={"menu-link"}
                            type="submit">Enroll to this Course
                    </button>
                </form>
            </>
        )
    }


    if (errorMessage) {
        simpleToast.error(errorMessage);
    }

    if (successMessage) {
        simpleToast.success(successMessage);
    }

    if (
        (course === undefined) ||
        (courseTags === undefined) ||
        (courseCategories === undefined)
    ) {
        return (
            <>
                <i>loading</i>
            </>
        )
    }

    return (
        <>
            <table border={1}>
                <tbody>
                <tr>
                    <td>
                        <b>Name</b>
                    </td>
                    <td>{course.name}</td>
                </tr>
                {courseTags.map((tag: CourseTag) =>
                    <tr>
                        <td>
                            <b>Tag</b>
                        </td>
                        <td>{tag.name}</td>
                    </tr>
                )}
                {courseCategories.map((category: CourseCategory) =>
                    <tr>
                        <td>
                            <b>Category</b>
                        </td>
                        <td>{category.name}</td>
                    </tr>
                )}
                </tbody>
            </table>

            <p/>
            <b>Course Modules</b>
            <p/>

            <CourseModulesListPageInner
                propertyMap={MapHelperInstance.build1("courseId", id?.toString() as string)}/>

            {
                (userSession?.roleId === RoleEnum.ADMIN)
                    ? <>            <p/><Link className={"menu-link"} to={"/courses/edit/" + id}>Edit Course</Link> </>
                    : <></>
            }
            {showEnrollmentButton()}
        </>
    )

}
