import React, {Dispatch, SetStateAction} from "react";
import toast from "react-hot-toast";
import axios, {AxiosError, AxiosResponse} from "axios";
import {Link, Navigate, useParams} from "react-router-dom";
import Organisation, {createOrganisation} from "../../types/Organisation";
import RestResponseWrapper from "../../core/RestResponseWrapper";
import Menu from "../../../components/Menu";
import {ServiceBaseURL} from "../../../components/ServiceConfiguration";

const ViewState_LOADING_EXISTING = 1;
const ViewState_SHOW_FORM = 4;
const ViewState_SUBMIT_DATA = 5;
const ViewState_GOTO_VIEW = 6;
const ViewState_FAILED = 9;

const RESOURCE_URL: string = ServiceBaseURL + "organisations/";

let errorCount = 0;

function showError(
	message : string,
	setError : Dispatch<SetStateAction<string | undefined>>
	) : void {
	toast.error(message, {
		id: 'error' + errorCount++,
	});
	console.log("error: " + message);
	setError(message);
}

function load(
	id : string,
	setViewState : Dispatch<SetStateAction<number>>,
	setResult : Dispatch<SetStateAction<Organisation | undefined>>,
	setError : Dispatch<SetStateAction<string | undefined>>
	) : void {
        axios.get(RESOURCE_URL + id)
            .then((responseObject: AxiosResponse) => {
                console.log(responseObject);
                const restResult: RestResponseWrapper<Organisation> = responseObject.data;
                if ((restResult.errorMessage !== null) && (restResult.errorMessage !== undefined)) {
                    showError(restResult.errorMessage as string, setError);
                    setViewState(ViewState_FAILED);
                } else {
                    if ((restResult.response === null) || (restResult.response === undefined)) {
	                    showError("No such Organisation", setError);
    	                setViewState(ViewState_FAILED);
                    } else {
                        setResult(restResult.response as Organisation);
    	                setViewState(ViewState_SHOW_FORM);
                        console.log("load successful");
                    }
                }
            })
            .catch((errorObject: AxiosError | Error) => {
                if (axios.isAxiosError(errorObject)) {
                    let axiosError = errorObject as AxiosError;
                    if (axiosError.code === 'ERR_NETWORK') {
						showError("network error: is the service running ?", setError);
	                    setViewState(ViewState_FAILED);
                    } else {
                        showError(axiosError.message, setError);
	                    setViewState(ViewState_FAILED);
                    }
                } else {
                        showError(errorObject.message, setError);
	                    setViewState(ViewState_FAILED);
                }
                // setLoading(false);
            });
}

export default function OrganisationsEditPage() {

    const {id} = useParams();

    const [active, setActive] = React.useState<boolean>(true);
    const [result, setResult] = React.useState<Organisation | undefined>(undefined);
    const [payload, setPayload] = React.useState<Organisation | undefined>(undefined);
    const [error, setError] = React.useState<string | undefined>(undefined);

    const [viewState, setViewState] = React.useState<number>(ViewState_LOADING_EXISTING);

    console.log("STATE: " + viewState);

	if (id === undefined) {
		showError("no entity id", setError);
	}

    React.useEffect(() => {
        console.log("React.useEffect()");

        if (viewState === ViewState_LOADING_EXISTING) {
			load(id as string, setViewState, setResult, setError);
        }

        if (viewState === ViewState_SUBMIT_DATA) {
            axios.put(RESOURCE_URL, payload)
                .then((responseObject: AxiosResponse) => {
                    console.log(responseObject);
                    const restResult: RestResponseWrapper<Organisation> = responseObject.data;
                    if (restResult.errorMessage !== null) {
                        setActive(false);
                        toast.error("login failed: " + restResult.errorMessage, {
                            id: 'error1',
                        });
                        console.log("load failed: " + restResult.errorMessage);
                    } else {
                        setResult(restResult.response as Organisation);
                        setActive(false);
                        console.log("load successful");
                    }
                })
                .catch((errorObject: AxiosError | Error) => {
                    if (axios.isAxiosError(errorObject)) {
                        let axiosError = errorObject as AxiosError;
                        if (axiosError.code === 'ERR_NETWORK') {
                            toast.error("network error: is the service running?", {
                                id: 'error2',
                            });
                            console.log("network error: is the service running?")
                        } else {
                            toast.error(axiosError.message, {
                                id: 'error3',
                            });
                            console.log(axiosError.message);
                        }
                    } else {
                        toast.error(errorObject.message, {
                            id: 'error4',
                        });
                        console.log(errorObject.message);
                    }
                    setActive(false);
                });

        }
    }, [payload]);

    if (viewState === ViewState_FAILED) {

        return (
            <>
	            <Menu/>
                <b>{error}</b>
            </>
        );
    }

    function formToEntity(form : HTMLFormElement) : Organisation | string {

        let formData = new FormData(form);
        let entity: Organisation = createOrganisation();

        console.log(formData);

        // name
        if (!formData.has("name")) {
            return "missing 'name'";
        }
        if ("" === (formData.get("name") as string)) {
            return "empty 'name'";
        }
        entity.name = formData.get("name") as string;

        return entity;
    }

    function showEditFormContent(organisation: Organisation) {
        let value_name = "";
        if ((organisation.name !== undefined)) {
            value_name = organisation.name as string;
        }

        let value_id_int = organisation.id as number;
        let value_id = value_id_int.toString();

        return (
            <>
                <tr>
                    <td valign="top"><label htmlFor='name'>name</label></td>
                    <td><input id='name' name='name' size={120} defaultValue={value_name}/></td>
                </tr>
                <input type="hidden" id="id" name="id" value="value_id" />
            </>
        )
    }

    function handleSubmit(event: { preventDefault: () => void; }) {
        event.preventDefault();

        console.log("handleSubmit()")

        const form = document.querySelector("form");
        if (form !== null) {

            let entity = formToEntity(form);
            
            console.log(entity);
            if (typeof entity === "string") {
                    console.error("ERROR(3):" + entity as string);
                    toast.error(entity as string, {
                        id: 'error1',
                    });
            } else {
                console.log("send");
              // we can send this
                setPayload(entity as Organisation );
                setViewState(ViewState_SUBMIT_DATA);
            }

        }
    }


    if (error) {
        console.error("ERROR(1):" + error);
        toast.error(error, {
            id: 'error1',
        });
        setViewState(ViewState_FAILED);
        return <div className='error-box'>{error}</div>;
    }

    if (viewState === ViewState_SUBMIT_DATA) {
        if (active) {
            console.log("sending data")
            return <b>TRANSMITTING</b>;
        } else {
            console.log("data sent")
                if (error) {
                    console.error("ERROR(2):" + error);
                    toast.error(error, {
                        id: 'error1',
                    });
                    setError("");
                    setViewState(ViewState_SHOW_FORM);
                } else {
                    toast.success("Organisation created", {
                        id: 'success2',
                    });
                    // setViewState(ViewState_ENIITY_CREATED);
                    setViewState(ViewState_GOTO_VIEW);
                }
        }
    }

    if (viewState === ViewState_SHOW_FORM) {

        return (
            <>
	            <Menu/>
	            <h1>Edit Organisation</h1>
                <div className="big-form">
                    <form className="big-form" id="formId" onSubmit={handleSubmit}>
                        <input type="hidden" id="timezone" name="timezone" value="Europe:Berlin"/>
                        <table className="full-width big-form">
                            <tbody className="big-form">
                            {showEditFormContent(result as Organisation)}
                            <tr>
                                <td>&nbsp;</td>
                                <td>
                                	<button type="submit">Update Organisation</button>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
            </>
        )
    }

    if (viewState === ViewState_GOTO_VIEW) {

        return (
            <>
                <Navigate to={"/"}/>
            </>
        );
    }

    return (
        <>
        <b>you shouldn't be here ...</b>
            <i>viewState: {viewState}</i>
        </>
    );

}
