import PageProperties from "../../core/PageProperties";
import Menu from "../../../components/Menu";
import UsersEditPageInner from "./UsersEditPageInner";

export default function UsersEditPage(properties: PageProperties | undefined = undefined) {

    return (
        <>
            <Menu />
            <h1>Edit User</h1>
            <UsersEditPageInner/>
        </>
    )

}
