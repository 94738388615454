import PageProperties from "./PageProperties";
import React from "react";

export default class PageHelper {

    private extractMap(properties: PageProperties | undefined): Map<string, string> {

        if ((properties === undefined) || (properties.propertyMap === undefined)) {
            return new Map<string, string>();
        }

        return new Map(properties.propertyMap);
    }

    private buildQuery(map: Map<string, string>): string {

        if (map.size === 0) {
            return "";
        }

        let query = "";

        map.forEach((value: string, key: string) => {
                if (query.length === 0) {
                    query += "?";
                } else {
                    query += "&";
                }
                query += key + "=\"" + value + "\"";
            }
        )

        console.log("query: '" + query + "'");
        return query;
    }


    public createQuery(properties: PageProperties | undefined, searchParams: URLSearchParams): string {

        let qMap = new Map<string, string>();

        if ((properties !== undefined) && (properties.propertyMap !== undefined)) {
            qMap = new Map(properties.propertyMap);
        }

        searchParams.forEach((value: string, key: string) => {
                qMap.set(key, value);
            }
        )

        return this.buildQuery(qMap);
    }
}

export const PageHelperInstance = new PageHelper();

